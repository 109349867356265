import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 -18 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 931.517 25.969 C 927.993 23.796 923.932 22.638 919.795 22.638 L 251.384 22.638 C 193.918 22.638 147.168 68.933 147.168 125.669 L 143.24 701.273 L 22.446 701.273 C 10.047 701.273 0 711.32 0 723.719 L 0 827.417 C 0.079 903.566 61.787 965.274 137.936 965.362 C 139.478 965.362 141.032 965.205 142.547 964.888 L 700.228 964.888 C 703.553 965.126 706.901 965.283 710.277 965.283 C 787.61 965.142 850.231 902.435 850.256 825.103 L 850.256 488.624 L 1001.554 488.624 C 1013.953 488.624 1024 478.577 1024 466.178 L 1024 132.982 C 1023.729 79.401 984.493 33.993 931.517 25.969 Z M 44.892 827.426 L 44.892 746.174 L 570.299 746.174 L 570.299 825.111 C 570.272 860.28 583.501 894.157 607.359 919.997 L 134.866 919.997 C 133.806 920.006 132.754 920.084 131.71 920.241 C 82.881 916.919 44.952 876.369 44.892 827.426 Z M 805.365 132.982 L 805.365 825.103 C 805.322 850.195 795.351 874.236 777.633 891.993 C 760.666 909.395 737.616 919.559 713.328 920.331 C 712.118 920.12 710.89 920.015 709.656 920.006 L 701.764 920.006 C 652.874 915.191 615.513 874.245 615.19 825.12 L 615.19 723.719 C 615.19 711.329 605.141 701.273 592.744 701.273 L 188.132 701.273 L 192.06 125.828 C 192.383 93.369 218.916 67.285 251.384 67.53 L 827.662 67.53 C 813.203 86.283 805.356 109.299 805.365 132.982 Z M 979.109 443.733 L 850.256 443.733 L 850.256 132.982 C 850.844 97.814 879.514 69.626 914.682 69.626 C 949.85 69.626 978.523 97.814 979.109 132.982 L 979.109 443.733 Z M 979.109 443.733" }, null, -1),
    _createElementVNode("path", { d: "M 288.173 265.807 L 500.759 265.807 C 513.158 265.807 523.205 255.758 523.205 243.361 C 523.205 230.964 513.158 220.915 500.759 220.915 L 288.173 220.915 C 275.776 220.915 265.727 230.964 265.727 243.361 C 265.727 255.758 275.776 265.807 288.173 265.807 Z M 288.173 265.807" }, null, -1),
    _createElementVNode("path", { d: "M 684.727 381.403 L 288.173 381.403 C 275.776 381.403 265.727 391.452 265.727 403.849 C 265.727 416.236 275.776 426.294 288.173 426.294 L 684.727 426.294 C 697.126 426.294 707.173 416.236 707.173 403.849 C 707.173 391.452 697.126 381.403 684.727 381.403 Z M 684.727 381.403" }, null, -1),
    _createElementVNode("path", { d: "M 684.727 541.881 L 288.173 541.881 C 275.776 541.881 265.727 551.939 265.727 564.327 C 265.727 576.724 275.776 586.773 288.173 586.773 L 684.727 586.773 C 697.126 586.773 707.173 576.724 707.173 564.327 C 707.173 551.939 697.126 541.881 684.727 541.881 Z M 684.727 541.881" }, null, -1)
  ])))
}
export default { render: render }