import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 512 0 C 229.686 0 0 229.686 0 512 C 0 794.314 229.686 1024 512 1024 C 794.314 1024 1024 794.314 1024 512 C 1024 229.686 794.314 0 512 0 Z M 708.923 551.385 L 315.077 551.385 C 284.759 551.385 265.81 518.564 280.969 492.308 C 288.004 480.122 301.006 472.615 315.077 472.615 L 708.923 472.615 C 739.241 472.615 758.19 505.436 743.031 531.692 C 735.996 543.878 722.994 551.385 708.923 551.385 Z" }, null, -1)
  ])))
}
export default { render: render }