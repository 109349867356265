import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-d90c18d4-0177-423d-bb8a-0e52901f3365-__Layer_2",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  "data-name": "Layer 2",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 1010.407 478.535 L 684.586 152.714 C 658.813 127.822 615.758 140.164 607.087 174.93 C 603.202 190.506 607.618 206.983 618.77 218.53 L 865.137 464.897 L 46.581 464.897 C 10.75 464.897 -11.645 503.686 6.271 534.716 C 14.586 549.118 29.952 557.989 46.581 557.989 L 865.137 557.989 L 618.77 804.357 C 592.997 829.249 603.835 872.706 638.279 882.58 C 654.821 887.322 672.631 882.551 684.586 870.173 L 1010.407 544.351 C 1028.578 526.175 1028.578 496.711 1010.407 478.535 Z" }, null, -1)
  ])))
}
export default { render: render }