import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-85fc42f6-757c-411e-b4a8-93fab866f7f0-__Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { transform: "matrix(2, 0, 0, 2, 0, 0)" }, [
      _createElementVNode("path", { d: "m331 1.509v58.491h58.491c-5.981-29.344-29.147-52.51-58.491-58.491z" }),
      _createElementVNode("path", { d: "m496 422h-22.5v-46c0-8.284-6.716-15-15-15h-187.5v-31h105c8.284 0 15-6.716 15-15v-225h-75c-8.284 0-15-6.716-15-15v-75h-165c-8.284 0-15 6.716-15 15v300c0 8.284 6.716 15 15 15h105v31h-187.5c-8.284 0-15 6.716-15 15v46h-22.5c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h75c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-22.5v-31h105v31h-22.5c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h75c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-22.5v-31h105v31h-22.5c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h75c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-22.5v-31h105v31h-22.5c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h75c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15zm-300-362h60c8.284 0 15 6.716 15 15s-6.716 15-15 15h-60c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 60h120c8.284 0 15 6.716 15 15s-6.716 15-15 15h-120c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 60h120c8.284 0 15 6.716 15 15s-6.716 15-15 15h-120c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 90c-8.284 0-15-6.716-15-15s6.716-15 15-15h120c8.284 0 15 6.716 15 15s-6.716 15-15 15z" })
    ], -1)
  ])))
}
export default { render: render }