import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  viewBox: "0 0 2078 2078"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".ec-855a6a0c-84ea-404f-9ae8-0821015c091f-__uuid-4b9e2b26-b6bf-411d-8477-69c19403a515{fill:#fff;filter:url(#ec-d2a9cdce-2ddd-4a2a-9362-7e043eb54cf6-__uuid-d222c5ae-e4d4-4730-9574-d7f0d56111ea);stroke:#e6e6e6;stroke-miterlimit:10}.ec-0b98624f-061a-4d9a-a875-4b78171a5f24-__uuid-f13efc01-9382-4e2f-9391-2eef1a5e0bec{fill:#2e86d3}")
        ])),
        _: 1
      })),
      _cache[1] || (_cache[1] = _createStaticVNode("<filter id=\"ec-d2a9cdce-2ddd-4a2a-9362-7e043eb54cf6-__uuid-d222c5ae-e4d4-4730-9574-d7f0d56111ea\" filterUnits=\"userSpaceOnUse\"><feOffset dx=\"0\" dy=\"24\"></feOffset><feGaussianBlur result=\"uuid-495adee0-d6bf-4eca-8118-6ae8eb2a7a49\" stdDeviation=\"16\"></feGaussianBlur><feFlood flood-color=\"#000\" flood-opacity=\".36\"></feFlood><feComposite in2=\"uuid-495adee0-d6bf-4eca-8118-6ae8eb2a7a49\" operator=\"in\"></feComposite><feComposite in=\"SourceGraphic\"></feComposite></filter>", 1))
    ]),
    _cache[2] || (_cache[2] = _createStaticVNode("<g id=\"ec-96d42e86-d0cd-4b62-92a3-6f250aa9e642-__uuid-13907597-b0e7-4f90-9c31-d02e053e5cd2\"><circle class=\"ec-855a6a0c-84ea-404f-9ae8-0821015c091f-__uuid-4b9e2b26-b6bf-411d-8477-69c19403a515\" cx=\"1039\" cy=\"1015\" r=\"990\"></circle></g><g id=\"ec-efc8d836-4a0b-4f4c-bf26-14d636c100a3-__uuid-a26a1696-055e-46e2-9714-61e4b9e37412\"><g><path class=\"ec-0b98624f-061a-4d9a-a875-4b78171a5f24-__uuid-f13efc01-9382-4e2f-9391-2eef1a5e0bec\" d=\"M913.82,259.99V1554.01h855.18V555.36L913.82,259.99Zm745.47,1132.98l-572.47-42.2V524.42l572.47,164.57v703.98Z\"></path><polygon class=\"ec-0b98624f-061a-4d9a-a875-4b78171a5f24-__uuid-f13efc01-9382-4e2f-9391-2eef1a5e0bec\" points=\"667.67 1554.01 847.71 1554.01 847.71 432.99 667.67 372.51 667.67 1554.01\"></polygon><polygon class=\"ec-0b98624f-061a-4d9a-a875-4b78171a5f24-__uuid-f13efc01-9382-4e2f-9391-2eef1a5e0bec\" points=\"467.94 1554.01 615.63 1554.01 615.63 545.52 467.94 503.32 467.94 1554.01\"></polygon><polygon class=\"ec-0b98624f-061a-4d9a-a875-4b78171a5f24-__uuid-f13efc01-9382-4e2f-9391-2eef1a5e0bec\" points=\"309 1554.01 432.78 1554.01 432.78 629.91 309 601.78 309 1554.01\"></polygon></g></g>", 2))
  ]))
}
export default { render: render }