import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "ec-d535f2a2-d08d-406b-96e2-48c0cd66b520-__Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(2, 0, 0, 2, 0, 0)\"><path d=\"m346 376h-83.789l-25.605-25.605c-2.813-2.813-6.622-4.395-10.606-4.395h-60c-8.291 0-15 6.709-15 15v136c0 8.291 6.709 15 15 15h180c8.291 0 15-6.709 15-15v-106c0-8.291-6.709-15-15-15z\"></path><path d=\"m176.605 260.395c-5.859-5.859-15.352-5.859-21.211 0l-4.394 4.394v-8.789c0-24.814-20.186-45-45-45-8.276 0-15-6.724-15-15v-15h45c8.291 0 15-6.709 15-15v-76h-75c-8.291 0-15-6.709-15-15v-75h-46c-8.291 0-15 6.709-15 15v151c0 8.291 6.709 15 15 15h46v15c0 24.814 20.186 45 45 45 8.276 0 15 6.724 15 15v8.789l-4.395-4.395c-5.859-5.859-15.352-5.859-21.211 0s-5.859 15.352 0 21.211l30 30c2.93 2.93 6.768 4.395 10.605 4.395s7.676-1.465 10.605-4.395l30-30c5.861-5.859 5.861-15.351.001-21.21z\"></path><path d=\"m91 1.518v58.482h58.482c-5.98-29.343-29.139-52.502-58.482-58.482z\"></path><path d=\"m271 1.518v58.482h58.482c-5.98-29.343-29.139-52.502-58.482-58.482z\"></path><path d=\"m316 181c8.291 0 15-6.709 15-15v-76h-75c-8.291 0-15-6.709-15-15v-75h-45c-8.291 0-15 6.709-15 15v151c0 8.291 6.709 15 15 15h45v83.789l-4.395-4.395c-5.859-5.859-15.352-5.859-21.211 0s-5.859 15.352 0 21.211l30 30c2.93 2.93 6.768 4.395 10.605 4.395s7.676-1.465 10.605-4.395l30-30c5.859-5.859 5.859-15.352 0-21.211s-15.352-5.859-21.211 0l-4.393 4.395v-83.789z\"></path><path d=\"m451 1.518v58.482h59.482c-5.98-29.343-30.139-52.502-59.482-58.482z\"></path><path d=\"m421 75v-75h-45c-8.291 0-15 6.709-15 15v151c0 8.291 6.709 15 15 15h45v15c0 8.276-6.724 15-15 15-24.814 0-45 20.186-45 45v8.789l-4.395-4.395c-5.859-5.859-15.352-5.859-21.211 0s-5.859 15.352 0 21.211l30 30c2.93 2.93 6.768 4.395 10.605 4.395s7.676-1.465 10.605-4.395l30-30c5.859-5.859 5.859-15.352 0-21.211s-15.352-5.859-21.211 0l-4.393 4.395v-8.789c0-8.276 6.724-15 15-15 24.814 0 45-20.186 45-45v-15h46c8.291 0 15-6.709 15-15v-76h-76c-8.291 0-15-6.709-15-15z\"></path></g>", 1)
  ])))
}
export default { render: render }