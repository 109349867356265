import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "rgb(0,0,0)",
      transform: "matrix(52.127171, 0, 0, 52.127171, -113.525558, -113.525475)"
    }, [
      _createElementVNode("path", { d: "m13.4639 5.28025-2.0814 2.08139c-.3905.39053-1.0237.39053-1.41419 0-.39052-.39052-.39053-1.02368 0-1.41421l2.08139-2.08139c2.188-2.18807 5.8486-2.18808 8.0367-.00006l.0946.09459c2.1881 2.18808 2.1882 5.84876.0001 8.03683l-2.0814 2.0814c-.3905.3905-1.0237.3905-1.4142 0s-.3905-1.0237 0-1.4142l2.0814-2.0814c1.407-1.40702 1.407-3.80127 0-5.20831l-.0946-.09459c-1.407-1.40703-3.8014-1.40708-5.2084-.00005z" }),
      _createElementVNode("path", { d: "m7.31444 9.9212c.39052.3905.39051 1.0237-.00002 1.4142l-2.08137 2.0813c-1.40703 1.4071-1.40703 3.8014 0 5.2084l.0946.0946c1.40705 1.4069 3.80138 1.407 5.20835 0l2.0814-2.0813c.3905-.3906 1.0237-.3906 1.4142-.0001.3905.3906.3905 1.0237 0 1.4143l-2.0814 2.0814c-2.18805 2.188-5.84868 2.188-8.03676 0l-.0946-.0947c-2.18792-2.1881-2.18803-5.8487 0-8.0368l2.08138-2.08132c.39053-.39052 1.02369-.39051 1.41422.00002z" }),
      _createElementVNode("path", { d: "m15.3623 10.0039c.387-.39394.3814-1.02708-.0125-1.41414-.394-.38705-1.0271-.38145-1.4142.01252l-5.29806 5.39272c-.38705.3939-.38145 1.0271.01251 1.4141.39397.3871 1.02711.3815 1.41415-.0125z" })
    ], -1)
  ])))
}
export default { render: render }