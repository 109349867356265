import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M 102.399 989.863 L 68.266 989.863 C 30.564 989.863 0 959.299 0 921.597 L 0 204.803 C 0 167.101 30.564 136.537 68.266 136.537 L 102.399 136.537 C 140.101 136.537 170.665 167.101 170.665 204.803 L 170.665 921.597 C 170.665 959.299 140.101 989.863 102.399 989.863 Z\" fill=\"currentColor\"></path><path d=\"M 477.862 341.335 C 477.862 303.633 447.299 273.069 409.596 273.069 L 273.064 273.069 C 235.362 273.069 204.798 303.633 204.798 341.335 L 204.798 401.068 C 204.798 405.781 208.619 409.601 213.331 409.601 L 469.329 409.601 C 474.042 409.601 477.862 405.781 477.862 401.068 Z\" fill=\"currentColor\"></path><path d=\"M 204.798 921.597 C 204.798 959.299 235.362 989.863 273.064 989.863 L 409.596 989.863 C 447.299 989.863 477.862 959.299 477.862 921.597 L 477.862 857.597 C 477.862 855.241 475.952 853.331 473.596 853.331 L 209.065 853.331 C 206.708 853.331 204.798 855.241 204.798 857.597 Z\" fill=\"currentColor\"></path><rect x=\"204.798\" y=\"477.867\" width=\"273.064\" height=\"307.197\" rx=\"2\" ry=\"2\" fill=\"currentColor\"></rect><path d=\"M 648.527 989.863 L 580.261 989.863 C 542.559 989.863 511.995 959.299 511.995 921.597 L 511.995 102.404 C 511.995 64.702 542.559 34.138 580.261 34.138 L 648.527 34.138 C 686.23 34.138 716.793 64.702 716.793 102.404 L 716.793 921.597 C 716.793 959.299 686.23 989.863 648.527 989.863 Z\" fill=\"currentColor\"></path><path d=\"M 1023.756 916.157 L 954.999 190.83 C 951.842 157.444 918.861 133.23 881.336 136.836 L 813.39 143.236 C 775.865 146.798 748.004 176.75 751.161 210.136 L 819.918 935.463 C 823.075 968.849 856.056 993.063 893.581 989.457 L 961.527 983.057 C 999.052 979.495 1026.913 949.543 1023.756 916.157 Z\" fill=\"currentColor\"></path>", 6)
  ])))
}
export default { render: render }