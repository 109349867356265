<template>
  <div ref="container" style="overflow: auto">
    <div ref="pullContainer" class="pull-refresh" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd" style="width: 100%; height: 100%">
      <div ref="refreshingIndicator" :style="refreshingIndicatorStyle" class="refreshing-indicator flex-row-nowrap-center-center-center">
        <n-spin v-if="loading" :size="12" style="margin-right: 8px"></n-spin>
        <div>{{ state.pullToRefresh.hintText }}</div>
      </div>
      <div ref="contentContainer">
        <slot name="content"></slot>
      </div>
      <div ref="loadMoreIndicator" class="load-more-indicator flex-row-nowrap-center-center-center">
        <n-spin v-if="loading" :size="12" style="margin-right: 8px"></n-spin>
        <div>{{ state.infiniteLoad.indicatorText }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, nextTick, computed } from "vue"
import { defineProps, defineEmits } from "vue"

const props = withDefaults(
  defineProps<{
    loading: boolean
    pullText?: string
    releaseText?: string
    refreshingText?: string
    threshold?: number
    hasMore: boolean
    loadMoreText?: string
    loadingText?: string
    noMoreText?: string
  }>(),
  {
    pullText: "下拉即可刷新",
    releaseText: "释放即可刷新",
    refreshingText: "刷新中...",
    threshold: 60,
    loadMoreText: "加载更多",
    loadingText: "加载中...",
    noMoreText: "没有更多了",
  }
)

const emits = defineEmits<{
  refresh: []
  loadMore: []
}>()

const container = ref<HTMLElement | null>(null)
const refreshingIndicator = ref<HTMLElement | null>(null)
const pullContainer = ref<HTMLElement | null>(null)
const loadMoreIndicator = ref<HTMLElement | null>(null)
const contentContainer = ref<HTMLElement | null>(null)

const state = reactive({
  infiniteLoad: {
    indicatorText: props.loadMoreText,
  },
  pullToRefresh: {
    lastScrollTop: 1,
    startY: 0,
    moveY: 0,
    hintText: "",
    pulling: false,
  },
})

const refreshingIndicatorStyle = computed(() => {
  let translateY = props.threshold
  if (refreshingIndicator.value) {
    translateY = refreshingIndicator.value.offsetHeight
  }
  let dY = state.pullToRefresh.moveY
  if (dY > props.threshold) {
    dY = props.threshold
  }

  return {
    opacity: dY / props.threshold,
  }
})

function onTouchStart(event: TouchEvent) {
  onPullToRefreshScroll(event)
}

function onTouchMove(event: TouchEvent) {
  onInfiniteScroll(event)
  onPullToRefreshScroll(event)
}

function onTouchEnd() {
  onPullToRefreshScrollEnd()
}

const onInfiniteScroll = (event: TouchEvent) => {
  if (props.loading) {
    return
  }
  if (!container.value || !contentContainer.value || !loadMoreIndicator.value) {
    return
  }
  const scrollTop = container.value?.scrollTop
  const loadMoreHeight = loadMoreIndicator.value?.offsetHeight
  const contentHeight = contentContainer.value?.scrollHeight
  const triggerLoadMore = scrollTop + container.value.clientHeight > contentHeight - loadMoreHeight
  if (event.touches.length > 0 && triggerLoadMore && !props.loading) {
    startLoading()
  }
}

const startLoading = () => {
  if (props.hasMore) {
    state.infiniteLoad.indicatorText = props.loadingText
    emits("loadMore")
  } else {
    console.log("nomore")
    state.infiniteLoad.indicatorText = props.noMoreText
  }
}

const onPullToRefreshScroll = (event: TouchEvent) => {
  if (props.loading || !container.value || event.touches.length <= 0) {
    return
  }
  const scrollTop = container.value?.scrollTop
  /** 判断是否滚动到顶，开始下拉 */
  if (scrollTop == 0 && state.pullToRefresh.lastScrollTop > 0) {
    state.pullToRefresh.startY = event.touches[0].clientY
    state.pullToRefresh.hintText = props.pullText
    console.log("startY", state.pullToRefresh.startY)
    state.pullToRefresh.pulling = true
  }
  if (scrollTop > 0 && state.pullToRefresh.lastScrollTop == 0) {
    state.pullToRefresh.pulling = false
  }
  state.pullToRefresh.lastScrollTop = scrollTop

  if (!state.pullToRefresh.pulling) {
    return
  }
  console.log("sd", state.pullToRefresh.pulling)

  const currentY = event.touches[0].clientY
  state.pullToRefresh.moveY = currentY - state.pullToRefresh.startY

  /** 根据是否达到阈值，显示不同的提示 */
  if (state.pullToRefresh.moveY > props.threshold) {
    state.pullToRefresh.hintText = props.releaseText
  } else {
    state.pullToRefresh.hintText = props.pullText
  }

  if (state.pullToRefresh.moveY > 0) {
    event.preventDefault()
    if (pullContainer.value) {
      pullContainer.value.style.transition = "none"
      pullContainer.value.style.transform = `translateY(${state.pullToRefresh.moveY}px)`
    }
  }
}

const onPullToRefreshScrollEnd = () => {
  state.pullToRefresh.pulling = false
  console.log("end")
  if (state.pullToRefresh.moveY >= props.threshold) {
    emits("refresh")
    startRefresh()
  } else {
    state.pullToRefresh.hintText = ""
    resetPullRefresh()
  }
}

const startRefresh = () => {
  state.pullToRefresh.hintText = props.refreshingText
  nextTick(() => {
    let translateY = props.threshold
    if (refreshingIndicator.value) {
      translateY = refreshingIndicator.value.offsetHeight
    }
    if (pullContainer.value) {
      pullContainer.value.style.transition = "transform 0.3s ease-out"
      pullContainer.value.style.transform = `translateY(${translateY}px)`
    }
  })
}

function resetPullRefresh() {
  state.pullToRefresh.hintText = ""
  if (pullContainer.value) {
    pullContainer.value.style.transition = "transform 0.3s ease-out"
    pullContainer.value.style.transform = `translateY(0px)`
  }
}

const onLoadingChanged = (loading: boolean) => {
  if (loading) {
    startRefresh()
  } else {
    resetPullRefresh()
  }
}

onLoadingChanged(props.loading)

// 监听 loading prop 的变化，同步到内部状态
watch(
  () => props.loading,
  (newVal) => {
    onLoadingChanged(newVal)
  }
)
watch(
  () => props.hasMore,
  (hasMore: boolean) => {
    if (hasMore) {
      state.infiniteLoad.indicatorText = props.loadMoreText
    } else {
      state.infiniteLoad.indicatorText = props.noMoreText
    }
  },
  {
    immediate: true,
  }
)
</script>

<style scoped>
.pull-refresh {
  position: relative;
  transition: transform 0.3s ease-out;
  will-change: transform;
}

.refreshing-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #666;
  padding-top: 4px;
  padding-bottom: 4px;
  box-sizing: border-box;
  transform: translateY(-100%);
}

.load-more-indicator {
  width: 100%;
  font-size: 12px;
  color: #666;
  padding-top: 4px;
  padding-bottom: 4px;
  box-sizing: border-box;
}
</style>
