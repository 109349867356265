import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-3836135e-2a14-4be4-9153-dda7a721f1fd-__Glyph",
  "enable-background": "new 0 0 32 32",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      id: "ec-46c988ab-58fd-4399-b806-c22869f3951c-__XMLID_294_",
      d: "M 393.846 512 C 393.846 577.142 446.858 630.154 512 630.154 C 577.142 630.154 630.154 577.142 630.154 512 C 630.154 446.858 577.142 393.846 512 393.846 C 446.858 393.846 393.846 446.858 393.846 512 Z"
    }, null, -1),
    _createElementVNode("path", {
      id: "ec-2975c474-1cf2-4e00-880f-eb9abb42f490-__XMLID_295_",
      d: "M 393.846 905.846 C 393.846 970.988 446.858 1024 512 1024 C 577.142 1024 630.154 970.988 630.154 905.846 C 630.154 840.704 577.142 787.692 512 787.692 C 446.858 787.692 393.846 840.704 393.846 905.846 Z"
    }, null, -1),
    _createElementVNode("path", {
      id: "ec-d096ba8e-03b7-483c-b6b3-a71b2b0ccc69-__XMLID_297_",
      d: "M 393.846 118.154 C 393.846 183.296 446.858 236.308 512 236.308 C 577.142 236.308 630.154 183.296 630.154 118.154 C 630.154 53.012 577.142 0 512 0 C 446.858 0 393.846 53.012 393.846 118.154 Z"
    }, null, -1)
  ])))
}
export default { render: render }