import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "ec-2bbacf95-acc8-4967-b6c3-56428e93aa88-__Layer_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 1024 1024",
  style: {"enable-background":"new 0 0 492 492"},
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { transform: "matrix(2.081301, 0, 0, 2.081301, -0.000039, -0.000057)" }, [
      _createElementVNode("g", null, [
        _createElementVNode("path", { d: "M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872 c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872 c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052 L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116 c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952 c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116 c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" })
      ])
    ], -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1)
  ])))
}
export default { render: render }