import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-6de65fda-537f-445c-9c60-cf7ac044cf24-__Layer_3",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:bx": "https://boxy-svg.com"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 895.195 128.814 C 880.775 114.391 857.379 114.391 842.959 128.814 L 512.001 459.774 L 181.056 128.814 C 166.618 114.378 143.24 114.378 128.804 128.814 C 114.38 143.235 114.38 166.63 128.804 181.053 L 459.763 512.011 L 128.804 842.954 C 114.38 857.377 114.38 880.769 128.804 895.193 C 136.022 902.412 145.471 906.014 154.92 906.014 C 164.372 906.014 173.822 902.412 181.038 895.193 L 512.001 564.235 L 842.959 895.193 C 850.178 902.412 859.626 906.014 869.078 906.014 C 878.527 906.014 887.979 902.412 895.195 895.193 C 909.621 880.769 909.621 857.377 895.195 842.954 L 564.239 511.998 L 895.195 181.053 C 909.621 166.63 909.621 143.235 895.195 128.814 Z",
      fill: "#dd4247",
      style: {},
      "bx:origin": "0.5 0.500002"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 564.239 512.011 L 730.063 346.185 C 731.248 333.21 731.881 320.068 731.881 306.785 C 731.881 285.589 730.34 264.766 727.354 244.404 L 511.985 459.774 L 181.056 128.814 C 166.618 114.378 143.24 114.378 128.804 128.814 C 122.585 135.049 119.044 142.943 118.199 151.085 L 118.199 158.78 C 119.044 166.909 122.568 174.816 128.804 181.053 L 459.763 512.011 L 241.374 730.396 C 261.738 733.368 282.561 734.922 303.753 734.922 C 317.037 734.922 330.185 734.291 343.156 733.108 L 512.001 564.263 L 580.861 633.123 C 599.653 617.15 617.061 599.589 632.853 580.639 L 564.239 512.027 Z",
      fill: "#e55353",
      style: {},
      "bx:origin": "0.641703 0.638663"
    }, null, -1)
  ])))
}
export default { render: render }