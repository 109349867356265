import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 12 845.334 L 1012 845.334 L 1012 734.214 L 12 734.214 Z M 12 567.547 L 1012 567.547 L 1012 456.454 L 12 456.454 Z M 12 178.667 L 12 289.787 L 1012 289.787 L 1012 178.667 Z" }, null, -1)
  ])))
}
export default { render: render }