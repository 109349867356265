import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 512 0 C 229.672 0 0 229.672 0 512 C 0 794.328 229.672 1024 512 1024 C 794.328 1024 1024 794.328 1024 512 C 1024 229.672 794.328 0 512 0 Z M 736 554.663 L 554.663 554.663 L 554.663 736 C 554.663 759.554 535.554 778.663 512 778.663 C 488.446 778.663 469.337 759.554 469.337 736 L 469.337 554.663 L 288 554.663 C 264.446 554.663 245.337 535.554 245.337 512 C 245.337 488.446 264.446 469.337 288 469.337 L 469.337 469.337 L 469.337 288 C 469.337 264.446 488.446 245.337 512 245.337 C 535.554 245.337 554.663 264.446 554.663 288 L 554.663 469.337 L 736 469.337 C 759.554 469.337 778.663 488.446 778.663 512 C 778.663 535.554 759.554 554.663 736 554.663 Z M 736 554.663" }, null, -1)
  ])))
}
export default { render: render }