import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "ec-0a47dc4c-6f4b-450d-85c3-da936566bde9-__Capa_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 1024 1024",
  style: {"enable-background":"new 0 0 512 512"},
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"><g><polygon points=\"512,349.091 512,232.727 331.636,232.727 331.636,270.546 171.636,270.546 171.636,203.637 302.545,203.637 \n\t\t\t302.545,17.454 0,17.454 0,203.637 130.909,203.637 130.909,456.728 331.636,456.728 331.636,494.546 512,494.546 512,378.183 \n\t\t\t331.636,378.183 331.636,416 171.63,416 171.636,311.273 331.636,311.273 331.636,349.091 \t\t\"></polygon></g></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 17.45402)\"></g>", 16)
  ])))
}
export default { render: render }