import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 657.24 262.834 C 657.24 262.834 712.419 235.652 512.271 235.652 C 233.276 235.652 58.904 537.672 198.402 779.288 C 337.899 1020.904 686.642 1020.904 826.139 779.288 C 857.948 724.193 874.695 661.694 874.695 598.076",
      fill: "none",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      style: {"stroke-width":"64px"}
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M 512.271 63.5 L 693.483 244.712 L 512.271 425.924",
      style: {"stroke-width":"64px"}
    }, null, -1)
  ])))
}
export default { render: render }