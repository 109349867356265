import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 895.998 367.31 L 895.998 121.31 C 896.153 71.832 856.057 31.654 806.578 31.71 L 560.718 31.71 C 544.597 31.743 529.139 38.132 517.698 49.49 L 26.177 540.87 C -8.725 575.885 -8.725 632.536 26.177 667.55 L 260.178 901.551 C 295.181 936.462 351.835 936.462 386.838 901.551 L 878.218 410.33 C 889.58 398.892 895.969 383.432 895.998 367.31 Z M 703.998 287.71 C 654.731 287.71 623.939 234.377 648.572 191.71 C 673.206 149.043 734.79 149.043 759.424 191.71 C 765.041 201.439 767.998 212.476 767.998 223.71 C 767.998 259.056 739.344 287.71 703.998 287.71 Z" }, null, -1),
    _createElementVNode("path", { d: "M 991.998 127.71 C 974.325 127.71 959.998 142.037 959.998 159.71 L 959.998 414.45 L 437.378 937.091 C 419.519 954.058 426.724 983.995 450.348 990.977 C 461.875 994.385 474.339 991.045 482.618 982.331 L 1006.618 458.33 C 1017.877 447.019 1024.137 431.669 1023.998 415.71 L 1023.998 159.71 C 1023.998 142.037 1009.671 127.71 991.998 127.71 Z" }, null, -1)
  ])))
}
export default { render: render }