import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-274dc5b4-5372-408a-b73c-66372fc00dbb-__Layer_1",
  "enable-background": "new 0 0 16 16",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "rgb(0,0,0)",
      transform: "matrix(85.333336, 0, 0, 85.333336, -170.666687, -213.333359)"
    }, [
      _createElementVNode("path", { d: "m13 6h-2v1h2v1h-1.5v1h1.5v1h-2v1h2c.3 0 .5-.1.7-.3s.3-.4.3-.7v-3c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3z" }),
      _createElementVNode("path", { d: "m9.5 11h-3v-2c0-.3.1-.5.3-.7s.4-.3.7-.3h1v-1h-2v-1h2c.3 0 .5.1.7.3s.3.4.3.7v1c0 .3-.1.5-.3.7s-.4.3-.7.3h-1v1h2z" }),
      _createElementVNode("path", { d: "m4 10v-4h-1v.5h-1v1h1v2.5h-1v1h3v-1z" })
    ], -1)
  ])))
}
export default { render: render }