import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  "clip-rule": "evenodd",
  "fill-rule": "evenodd",
  height: "48px",
  "stroke-linejoin": "round",
  "stroke-miterlimit": "2",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g id=\"ec-c4b27f1f-1b67-4d30-8754-b5927393b12a-__Icon\" transform=\"matrix(48.761906, 0, 0, 48.761906, -85.333359, -73.289169)\"><circle cx=\"12\" cy=\"7\" r=\"5.5\"></circle><path d=\"m18.5 13.506h.5c.332 0 .649.132.884.366.234.235.366.553.366.884v1.5c0 .414.336.75.75.75s.75-.336.75-.75v-1.5c0-.729-.29-1.429-.805-1.944-.516-.516-1.216-.806-1.945-.806-.018 0-.037.001-.055.002-.017-.001-.035-.002-.053-.002-.132 0-.344 0-.385 0h-.007c-.729 0-1.429.29-1.945.806-.515.515-.805 1.215-.805 1.944v1.5c0 .414.336.75.75.75s.75-.336.75-.75v-1.5c0-.331.132-.649.366-.884.235-.234.552-.366.884-.366z\"></path><path d=\"m14.053 22.012c-.194-.376-.303-.803-.303-1.256v-3.5c0-.854.389-1.617 1-2.121v-.379c0-.401.064-.796.187-1.17-.928-.145-1.915-.224-2.937-.224-3.322 0-6.263.831-8.089 2.076-1.393.95-2.161 2.157-2.161 3.424v1.45c0 .451.179.884.498 1.203.319.318.751.498 1.202.497z\"></path><path d=\"m22.75 20.756v-3.5c0-.966-.784-1.75-1.75-1.75-1.222 0-3.278 0-4.5 0-.966 0-1.75.784-1.75 1.75v3.5c0 .967.784 1.75 1.75 1.75h4.5c.966 0 1.75-.783 1.75-1.75zm-4.75-2.25v1c0 .414.336.75.75.75s.75-.336.75-.75v-1c0-.414-.336-.75-.75-.75s-.75.336-.75.75z\"></path></g>", 1)
  ])))
}
export default { render: render }