import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "512",
  viewBox: "0 0 192 192",
  width: "512",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m16 176v-136h-16v144a8 8 0 0 0 8 8h184v-16z" }, null, -1),
    _createElementVNode("path", { d: "m72 112a8 8 0 0 0 -8-8h-24a8 8 0 0 0 -8 8v56h40z" }, null, -1),
    _createElementVNode("path", { d: "m128 80a8 8 0 0 0 -8-8h-24a8 8 0 0 0 -8 8v88h40z" }, null, -1),
    _createElementVNode("path", { d: "m184 48a8 8 0 0 0 -8-8h-24a8 8 0 0 0 -8 8v120h40z" }, null, -1)
  ])))
}
export default { render: render }