import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 836.164 170.685 L 503.616 170.685 C 493.516 170.717 483.633 167.747 475.223 162.152 L 415.92 122.496 C 396.282 109.364 373.179 102.376 349.555 102.422 L 187.666 102.422 C 121.719 102.493 68.276 155.935 68.205 221.882 L 68.205 273.08 L 955.624 273.08 C 955.624 207.206 902.037 170.685 836.164 170.685 Z" }, null, -1),
    _createElementVNode("path", { d: "M 869.762 921.578 L 154.067 921.578 C 88.851 921.504 35.746 869.139 34.757 803.931 L 0.326 452.504 L 0.326 451.907 C -4.441 392.348 42.588 341.376 102.337 341.342 L 921.706 341.342 C 981.438 341.401 1028.44 392.365 1023.673 451.907 L 1023.673 452.504 L 989.073 803.931 C 988.083 869.139 934.978 921.504 869.762 921.578 Z M 989.542 449.177 Z" }, null, -1)
  ])))
}
export default { render: render }