import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "ec-445511b7-a51b-4d1f-80c0-c982645625e4-__Capa_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 1024 1024",
  style: {"enable-background":"new 0 0 426.667 426.667"},
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(2.399998, 0, 0, 2.399998, 0.000028, 0.001274)\"><g><circle cx=\"42.667\" cy=\"213.333\" r=\"42.667\"></circle></g></g><g transform=\"matrix(2.399998, 0, 0, 2.399998, 0.000028, 0.001274)\"><g><circle cx=\"213.333\" cy=\"213.333\" r=\"42.667\"></circle></g></g><g transform=\"matrix(2.399998, 0, 0, 2.399998, 0.000028, 0.001274)\"><g><circle cx=\"384\" cy=\"213.333\" r=\"42.667\"></circle></g></g>", 3)
  ])))
}
export default { render: render }