import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-a47a46e3-bead-4e02-b992-82748acc89c2-__Layer_1",
  "enable-background": "new 0 0 512 512",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "clip-rule": "evenodd",
      d: "M 504.651 860.908 L 504.651 911.939 C 504.651 952.863 484.256 988.194 448.814 1008.654 C 431.762 1018.498 412.731 1023.99 392.999 1024 C 373.249 1024.01 354.222 1018.508 337.158 1008.654 L 110.246 877.646 C 74.8 857.183 54.409 821.862 54.409 780.932 L 54.409 111.676 C 54.409 50.094 104.505 0 166.085 0 L 665.826 0 C 727.41 0 777.51 50.089 777.51 111.676 L 777.51 252.753 C 777.51 272.992 761.078 289.424 740.839 289.424 C 720.596 289.424 704.178 272.994 704.178 252.753 L 704.178 111.676 C 704.178 90.53 686.971 73.324 665.826 73.324 L 227.922 73.324 L 448.814 200.877 C 484.244 221.337 504.651 256.658 504.651 297.572 L 504.651 787.577 L 665.826 787.577 C 686.969 787.577 704.178 770.38 704.178 749.232 L 704.178 625.561 C 704.178 605.309 720.586 588.891 740.839 588.891 C 761.086 588.891 777.51 605.313 777.51 625.561 L 777.51 749.232 C 777.51 810.819 727.41 860.908 665.826 860.908 Z M 844.418 467.115 L 804.035 507.498 C 789.717 521.816 789.722 545.028 804.035 559.347 C 810.928 566.244 820.216 570.074 829.961 570.074 C 839.718 570.074 848.995 566.254 855.897 559.347 L 958.855 456.372 C 973.168 442.054 973.17 418.854 958.855 404.538 L 855.897 301.579 C 841.577 287.259 818.363 287.265 804.037 301.577 C 789.724 315.881 789.728 339.103 804.037 353.411 L 844.418 393.783 L 573.899 393.783 C 553.64 393.783 537.236 410.2 537.236 430.454 C 537.236 450.711 553.642 467.117 573.899 467.117 L 844.418 467.117 Z",
      "fill-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }