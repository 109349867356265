import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "rgb(0,0,0)",
      transform: "matrix(64, 0, 0, 52.527939, -256, -118.335266)",
      style: {}
    }, [
      _createElementVNode("path", {
        d: "m12.7071 4.29289c-.3905-.39052-1.0237-.39052-1.4142 0l-3.00001 3c-.39052.39053-.39052 1.02369 0 1.41422.39053.39052 1.02369.39052 1.41422 0l1.29289-1.2929v7.58579c0 .5523.4477 1 1 1s1-.4477 1-1v-7.58579l1.2929 1.2929c.3905.39052 1.0237.39052 1.4142 0 .3905-.39053.3905-1.02369 0-1.41422z",
        style: {"fill":"rgb(255, 255, 255)"}
      }),
      _createElementVNode("path", {
        d: "m4 19c0-.5523.44772-1 1-1h14c.5523 0 1 .4477 1 1s-.4477 1-1 1h-14c-.55228 0-1-.4477-1-1z",
        style: {"fill":"rgb(255, 255, 255)"}
      })
    ], -1)
  ])))
}
export default { render: render }