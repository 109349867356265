import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1663831158024",
  class: "ec-e8c361e7-7d26-4c38-8b72-365fcbfcf195-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "8299",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 699.605 253.66 L 699.605 2.4 C 699.605 1.08 698.525 0 697.206 0 L 102.412 0 C 74.094 0 51.176 22.918 51.176 51.236 L 51.176 972.764 C 51.176 1001.082 74.094 1024 102.412 1024 L 921.588 1024 C 949.906 1024 972.824 1001.082 972.824 972.764 L 972.824 258.46 C 972.824 257.14 971.744 256.06 970.424 256.06 L 702.005 256.06 C 700.685 256.06 699.605 254.98 699.605 253.66 Z M 204.764 207.224 C 204.764 178.906 227.682 155.988 256 155.988 L 460.824 155.988 C 489.142 155.988 512.06 178.906 512.06 207.224 L 512.06 514.4 C 512.06 542.718 489.142 565.636 460.824 565.636 L 256 565.636 C 227.682 565.636 204.764 542.718 204.764 514.4 L 204.764 207.224 Z M 767.88 872.812 L 256 872.812 C 227.682 872.812 204.764 849.894 204.764 821.576 C 204.764 793.258 227.682 770.34 256 770.34 L 768 770.34 C 796.318 770.34 819.236 793.258 819.236 821.576 C 819.236 849.894 796.198 872.812 767.88 872.812 Z M 767.88 719.224 L 256 719.224 C 227.682 719.224 204.764 696.306 204.764 667.988 C 204.764 639.67 227.682 616.752 256 616.752 L 768 616.752 C 796.318 616.752 819.236 639.67 819.236 667.988 C 819.236 696.306 796.198 719.224 767.88 719.224 Z M 767.88 565.636 L 614.292 565.636 C 585.974 565.636 563.056 542.718 563.056 514.4 C 563.056 486.082 585.974 463.164 614.292 463.164 L 767.88 463.164 C 796.198 463.164 819.116 486.082 819.116 514.4 C 819.116 542.718 796.198 565.636 767.88 565.636 Z M 767.88 412.048 L 614.292 412.048 C 585.974 412.048 563.056 389.13 563.056 360.812 C 563.056 332.494 585.974 309.576 614.292 309.576 L 767.88 309.576 C 796.198 309.576 819.116 332.494 819.116 360.812 C 819.116 389.13 796.198 412.048 767.88 412.048 Z",
      fill: "#009F72",
      "p-id": "8300"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 750.841 3.48 L 750.841 202.424 C 750.841 203.744 751.921 204.824 753.241 204.824 L 967.184 204.824 C 968.624 204.824 969.824 203.504 969.584 202.064 C 952.786 94.073 863.872 10.079 753.481 1.08 C 752.041 0.96 750.841 2.04 750.841 3.48 Z",
      fill: "#F9DB88",
      "p-id": "8301"
    }, null, -1)
  ])))
}
export default { render: render }