import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-eba966b0-0670-4edc-aefb-73f0b78caef0-__Layer_1",
  "enable-background": "new 0 0 24 24",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 975.238 463.238 L 48.762 463.238 C 19.505 463.238 0 482.743 0 512 C 0 541.257 19.505 560.762 48.762 560.762 L 975.238 560.762 C 1004.495 560.762 1024 541.257 1024 512 C 1024 482.743 1004.495 463.238 975.238 463.238 Z M 585.143 804.572 L 438.857 804.572 C 409.6 804.572 390.095 824.076 390.095 853.334 C 390.095 882.591 409.6 902.095 438.857 902.095 L 585.143 902.095 C 614.4 902.095 633.905 882.591 633.905 853.334 C 633.905 824.076 614.4 804.572 585.143 804.572 Z M 975.238 804.572 L 828.952 804.572 C 799.695 804.572 780.19 824.076 780.19 853.334 C 780.19 882.591 799.695 902.095 828.952 902.095 L 975.238 902.095 C 1004.495 902.095 1024 882.591 1024 853.334 C 1024 824.076 1004.495 804.572 975.238 804.572 Z M 438.857 219.429 L 585.143 219.429 C 614.4 219.429 633.905 199.924 633.905 170.667 C 633.905 141.41 614.4 121.905 585.143 121.905 L 438.857 121.905 C 409.6 121.905 390.095 141.41 390.095 170.667 C 390.095 199.924 409.6 219.429 438.857 219.429 Z M 195.048 804.572 L 48.762 804.572 C 19.505 804.572 0 824.076 0 853.334 C 0 882.591 19.505 902.095 48.762 902.095 L 195.048 902.095 C 224.305 902.095 243.81 882.591 243.81 853.334 C 243.81 824.076 224.305 804.572 195.048 804.572 Z M 48.762 219.429 L 195.048 219.429 C 224.305 219.429 243.81 199.924 243.81 170.667 C 243.81 141.41 224.305 121.905 195.048 121.905 L 48.762 121.905 C 19.505 121.905 0 141.41 0 170.667 C 0 199.924 19.505 219.429 48.762 219.429 Z M 828.952 219.429 L 975.238 219.429 C 1004.495 219.429 1024 199.924 1024 170.667 C 1024 141.41 1004.495 121.905 975.238 121.905 L 828.952 121.905 C 799.695 121.905 780.19 141.41 780.19 170.667 C 780.19 199.924 799.695 219.429 828.952 219.429 Z",
      fill: "rgb(0,0,0)"
    }, null, -1)
  ])))
}
export default { render: render }