import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-2512d4b9-40b8-4826-83a9-9e48d9a2cf29-__Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".ec-d0a92a12-acb0-48c3-9f01-067608051a70-__cls-1{fill:none}.ec-fdcd247a-7eba-4919-a5f4-3ed7d01cbb98-__cls-2{clip-path:url(#ec-49408d36-008c-4d5e-9d69-a1fd3977cfda-__clippath)}")
        ])),
        _: 1
      })),
      _cache[1] || (_cache[1] = _createElementVNode("clipPath", { id: "ec-49408d36-008c-4d5e-9d69-a1fd3977cfda-__clippath" }, [
        _createElementVNode("rect", {
          class: "ec-d0a92a12-acb0-48c3-9f01-067608051a70-__cls-1",
          width: "384",
          height: "384"
        })
      ], -1))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("g", {
      class: "ec-fdcd247a-7eba-4919-a5f4-3ed7d01cbb98-__cls-2",
      transform: "matrix(2.666875, 0, 0, 2.666875, -0.053399, -0.039937)"
    }, [
      _createElementVNode("path", { d: "M149.38,379.13c-1.16,0-2.34-.14-3.52-.42-40.83-9.84-77.82-33.43-104.16-66.45-13.17-16.5-23.42-34.91-30.48-54.71C-43.58,102.21,112.57-51,265.46,16.27c22.67,10.13,43.08,24.54,60.67,42.8,13.22,14.88-7.25,34.57-21.6,20.79C204.26-25.81,29.91,49.48,30.19,193.6c0,74.42,50.45,138.55,122.69,155.96,8.05,1.94,13,10.04,11.06,18.09-1.66,6.87-7.8,11.48-14.56,11.48h0ZM383.98,92.42V49.7c-.79-19.89-29.2-19.88-29.98,0v42.72c0,8.27-6.72,14.99-14.99,14.99h-42.72c-19.89,.79-19.88,29.2,0,29.99h42.72c24.8,0,44.98-20.18,44.98-44.98h0Zm-18.74,82.45c24.86,.99,24.84,36.5,0,37.48-24.86-.99-24.85-36.5,0-37.48h0Zm-29.98,79.45c0,10.35,8.39,18.74,18.74,18.74,24.86-.99,24.84-36.5,0-37.48-10.35,0-18.74,8.39-18.74,18.74h0Zm-32.23,54.72c0,10.35,8.39,18.74,18.74,18.74,24.86-.99,24.84-36.5,0-37.48-10.35,0-18.74,8.39-18.74,18.74h0Zm-49.47,38.23c0,10.35,8.39,18.74,18.74,18.74,24.86-.99,24.85-36.5,0-37.48-10.35,0-18.74,8.39-18.74,18.74h0Zm-62.22,17.99c0,10.35,8.39,18.74,18.74,18.74,24.86-.99,24.84-36.5,0-37.48-10.35,0-18.74,8.39-18.74,18.74h0Zm0,0" })
    ], -1))
  ]))
}
export default { render: render }