import router from "@/router"
import { createApp } from "vue"
import App from "./App.vue"
import "@css/common.css"
import "@css/ec/ellipsis.css"
import "@css/flex.css"
import "@css/responsive.css"
import "@css/display-helper.css"
import { createPinia } from "pinia"
import OrganizationBreadcrumb from "@/organization/basic/components/OrganizationBreadcrumb.vue"
import EcIcon from "@/components/EcIcon.vue"
import EcColumn from "@/components/column/EcColumn.vue"
import EcFormBox from "@/components/EcFormBox.vue"
import EcFormItem from "@/components/EcFormItem.vue"
import EcEditableLabel from "@/components/EcEditableLabel.vue"
import EcButton from "@/components/EcButton.vue"
import EcSearchFilter from "@/components/EcSearchFilter.vue"
import EcSearchInput from "@/components/EcSearchInput.vue"
import EcTable from "@/components/EcTable.vue"
import EcHighlightLabel from "@/components/EcHighlightLabel.vue"
import EcPieChart from "@/components/charts/EcPieChart.vue"
import EcBarChart from "@/components/charts/EcBarChart.vue"
import EcEllipsis from "@/components/EcEllipsis.vue"
import EcNumberInput from "@/components/EcNumberInput.vue"
import EcUploadInput from "@/components/EcUploadInput.vue"
import EcUploadButton from "@/components/EcUploadButton.vue"
import EcTypeLabel from "@/components/EcTypeLabel.vue"
import EcSegment from "@/components/EcSegment.vue"
import EcDivider from "@/components/EcDivider.vue"
import EcAddressSelect from "@/components/EcAddressSelect.vue"
import EcAddressCascader from "@/components/EcAddressCascader.vue"
import EcHint from "@/components/EcHint.vue"
import EcNumberField from "@/components/EcNumberField.vue"
import { initSentry } from "@/util/SentryHelper"
import EcList from "@/components/EcList.vue"

import {
  // create naive ui
  create,
  // component
  NButton,
  NSpace,
  NLayout,
  NLayoutContent,
  NLayoutFooter,
  NLayoutHeader,
  NLayoutSider,
  NMenu,
  NTabs,
  NTabPane,
  NList,
  NListItem,
  NAvatar,
  NDropdown,
  NGrid,
  NGridItem,
  NIcon,
  NText,
  NH1,
  NH2,
  NH3,
  NH4,
  NH5,
  NH6,
  NSpin,
  NUl,
  NLi,
  NOl,
  NP,
  NForm,
  NFormItem,
  NResult,
  NCard,
  NInput,
  NSwitch,
  NRadio,
  NRadioButton,
  NRadioGroup,
  NInputNumber,
  NSelect,
  NModal,
  NCheckboxGroup,
  NCheckbox,
  NTreeSelect,
  NPopover,
  NConfigProvider,
  NDivider,
  NDrawer,
  NDrawerContent,
  NSteps,
  NStep,
  NDataTable,
  NTag,
  NTree,
  NProgress,
  NCarousel,
  NImage,
  NBreadcrumb,
  NBreadcrumbItem,
  NDatePicker,
  NTimePicker,
  NAffix,
  NSlider,
  NPagination,
  NPopselect,
  NTooltip,
  NPopconfirm,
  NMessageProvider,
  NDialogProvider,
  NSkeleton,
  NScrollbar,
  NTab,
  NInputGroup,
  NInputGroupLabel,
  NEllipsis,
  NNumberAnimation,
  NVirtualList,
  NCascader,
  NInfiniteScroll,
  NAlert,
  NCollapse,
  NCollapseItem,
} from "naive-ui"

const naive = create({
  components: [
    NButton,
    NSpace,
    NLayout,
    NLayoutContent,
    NLayoutFooter,
    NLayoutHeader,
    NLayoutSider,
    NMenu,
    NTabs,
    NTabPane,
    NList,
    NListItem,
    NAvatar,
    NDropdown,
    NGrid,
    NGridItem,
    NIcon,
    NText,
    NH1,
    NH2,
    NH3,
    NH4,
    NH5,
    NH6,
    NSpin,
    NUl,
    NLi,
    NOl,
    NP,
    NForm,
    NFormItem,
    NResult,
    NCard,
    NInput,
    NInputGroup,
    NSwitch,
    NRadio,
    NRadioButton,
    NRadioGroup,
    NInputNumber,
    NSelect,
    NModal,
    NCheckboxGroup,
    NCheckbox,
    NTreeSelect,
    NPopover,
    NConfigProvider,
    NDivider,
    NDrawer,
    NDrawerContent,
    NSteps,
    NStep,
    NDataTable,
    NTag,
    NTree,
    NProgress,
    NCarousel,
    NImage,
    NBreadcrumb,
    NBreadcrumbItem,
    NDatePicker,
    NTimePicker,
    NAffix,
    NSlider,
    NPagination,
    NPopselect,
    NTooltip,
    NPopconfirm,
    NMessageProvider,
    NDialogProvider,
    NSkeleton,
    NScrollbar,
    NTab,
    NInputGroupLabel,
    NEllipsis,
    NNumberAnimation,
    NVirtualList,
    NCascader,
    NInfiniteScroll,
    NAlert,
    NCollapse,
    NCollapseItem,
  ],
})

const pinia = createPinia()
const app = createApp(App)

initSentry(app, router)
app.use(pinia)
app.use(router)
app.use(naive)
app
  .component("OrganizationBreadcrumb", OrganizationBreadcrumb)
  .component("EcIcon", EcIcon)
  .component("EcColumn", EcColumn)
  .component("EcFormBox", EcFormBox)
  .component("EcFormItem", EcFormItem)
  .component("EcEditableLabel", EcEditableLabel)
  .component("EcButton", EcButton)
  .component("EcSearchFilter", EcSearchFilter)
  .component("EcSearchInput", EcSearchInput)
  .component("EcTable", EcTable)
  .component("EcHighlightLabel", EcHighlightLabel)
  .component("EcPieChart", EcPieChart)
  .component("EcBarChart", EcBarChart)
  .component("EcEllipsis", EcEllipsis)
  .component("EcNumberInput", EcNumberInput)
  .component("EcUploadInput", EcUploadInput)
  .component("EcUploadButton", EcUploadButton)
  .component("EcTypeLabel", EcTypeLabel)
  .component("EcSegment", EcSegment)
  .component("EcDivider", EcDivider)
  .component("EcAddressSelect", EcAddressSelect)
  .component("EcAddressCascader", EcAddressCascader)
  .component("EcHint", EcHint)
  .component("EcNumberField", EcNumberField)
  .component("EcList", EcList)

app.mount("#app")
