import { ApiResponse } from "@/common/model/ApiModel"
import { User } from "@/user/model/UserModel"
import request from "@/util/request"
import { LoginResponse, ResetPasswordParam, JoinParam, LoginParam, LoginBySmsParam, RefreshTokenResponse } from "@/auth/model/AuthApiModel"
import { encrypt } from "@/util/security/sm2/SM2Util"

export function join(param: JoinParam): Promise<User> {
  return request.post("/v3/auth/join", {
    M: encrypt(param.phonePrefix),
    C: encrypt(param.phone),
    A: encrypt(param.password),
    D: encrypt(param.name),
    E: encrypt(param.code),
  })
}

export function login(param: LoginParam): Promise<LoginResponse> {
  return request.post("/v3/auth/login", {
    C: encrypt(param.phonePrefix),
    M: encrypt(param.phone),
    D: encrypt(param.password),
  })
}

export function loginBySms(param: LoginBySmsParam): Promise<LoginResponse> {
  return request.post("/v3/auth/loginSms", {
    C: encrypt(param.phonePrefix),
    E: encrypt(param.phone),
    O: encrypt(param.code),
  })
}

export function refreshToken(refreshToken: string): Promise<RefreshTokenResponse> {
  return request.post("/v3/auth/token", {
    V: encrypt(refreshToken),
  })
}

export function changePassword(oldPassword: string, newPassword: string): Promise<ApiResponse> {
  return request.post(`/v3/auth/changePassword`, {
    L: encrypt(oldPassword),
    A: encrypt(newPassword),
  })
}

export function resetPassword(param: ResetPasswordParam) {
  return request.post(`/v3/auth/resetPassword`, {
    O: encrypt(param.phone),
    W: encrypt(param.code),
    C: encrypt(param.newPassword),
  })
}
