import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "ec-b981f44b-d794-48ab-b633-408cfb31c260-___图层_1",
  viewBox: "0 0 48 47.99",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".ec-d206eb0e-1689-4e14-9db3-09efdda73b74-__cls-1{fill:url(#ec-e2a2ddab-a6d8-4959-9f53-f3c2bad9ba89-__linear-gradient)}.ec-9e8c4cbf-ba96-4e3c-91d4-a1cf7a920d23-__cls-2{fill:url(#ec-d7d1f916-8b4e-4085-af5f-adbec7489284-__linear-gradient-3)}.ec-7c64ddf5-64cc-40dd-a0b0-552e1877cbb6-__cls-3{fill:url(#ec-9e7630f7-6aa8-490e-90eb-549267a61019-__linear-gradient-4)}.ec-cd5457ae-256c-41f4-9c58-3701dacc43cf-__cls-4{fill:url(#ec-0d02fdd5-d321-4228-a1b7-db25fd8f2a8b-__linear-gradient-2)}.ec-68c230a9-c415-4fdc-8065-205e804fb011-__cls-5{fill:url(#ec-c26dbd10-ffa1-4f4c-a261-ffa9802b50b5-__linear-gradient-5)}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<path class=\"ec-d206eb0e-1689-4e14-9db3-09efdda73b74-__cls-1\" d=\"M9.41,47.99c2.89-1.86,5.19-5.13,6.72-9.64,.86-2.62,1.44-5.33,1.71-8.08-5,.42-8.23,.69-14.47,1.21-3.97,.34-4.04,3.75-2.21,5.43,2.66,2.42,7.07,5.55,8.23,11.08h.02Z\" style=\"fill:rgb(210, 0, 60);\"></path><path class=\"ec-cd5457ae-256c-41f4-9c58-3701dacc43cf-__cls-4\" d=\"M9.41,47.99h0c2.89-1.86,5.19-5.13,6.72-9.64,1.54-4.9,2.2-10.03,1.94-15.15-4.31-.21-5.39-.32-12.77-.58-4.87-.17-4.65,4.63-3.37,6.57,3.72,5.59,8.38,14.74,7.48,18.8h0Z\" style=\"fill:rgb(0, 134, 202);\"></path><path class=\"ec-9e8c4cbf-ba96-4e3c-91d4-a1cf7a920d23-__cls-2\" d=\"M9.41,47.99c2.89-1.86,5.19-5.13,6.72-9.64,1.77-5.24,2.38-11.81,1.72-18.5-.11-1.17-.31-3.24-.48-4.97l-8.03-.98c-6.47-.76-6.78,3.2-5,6.73,8.16,16.14,6.29,25.54,5.06,27.37h0Z\" style=\"fill:rgb(0, 200, 120);\"></path><path class=\"ec-7c64ddf5-64cc-40dd-a0b0-552e1877cbb6-__cls-3\" d=\"M48,18.61c0-11.05-7.47-15.63-11.32-16.33-1.83-.34-3.89-.7-6.09-1.08L24.84,.19c-.95-.18-1.92-.23-2.89-.14-1.76,.12-3.37,1.04-4.36,2.51-.82,1.31-1.17,2.87-1,4.41,.22,2.13,.34,3.46,.49,5.13,.08,.81,.53,5.69,.76,7.75,.66,6.69,.05,13.27-1.72,18.5-1.52,4.51-3.81,7.78-6.72,9.64l14.1-1.93c2.78-.37,4.57-2.52,5.7-5.38,1.2-3.12,1.89-7.04,2.11-12.55,3.46,.54,7.67,.59,9.66,.05,2.29-.64,7.02-2.51,7.02-9.56Zm-12.37,1.26l-6.74-.65c-.74-.11-1.31-.7-1.4-1.44l-.62-7.06c-.12-.69,.49-1.28,1.17-1.13l6.75,1.07c2.5,.51,4.37,2.59,4.62,5.12,.21,2.48-1.46,4.33-3.78,4.09Z\" style=\"fill:rgb(245, 108, 0);\"></path><path class=\"ec-68c230a9-c415-4fdc-8065-205e804fb011-__cls-5\" d=\"M9.41,47.99c2.89-1.86,5.19-5.13,6.72-9.64,1.77-5.24,2.38-11.81,1.72-18.5-.21-2.07-.66-6.95-.76-7.75-.15-1.68-.27-3-.49-5.13-.04-.41-.04-.82,0-1.23l-2.23-.38c-6.52-1.11-7.32,3.19-5.89,6.51,8.41,19.7,3.97,33.63,.96,36.13h-.02Z\" style=\"fill:rgb(255, 210, 0);\"></path>", 5))
  ]))
}
export default { render: render }