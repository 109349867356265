import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 -12 1024 1022",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 1016.501 71.296 L 965.298 20.101 C 955.313 10.108 939.11 10.101 929.118 20.085 C 929.11 20.093 929.102 20.093 929.102 20.101 L 621.899 327.296 C 617.102 332.101 614.399 338.609 614.399 345.398 L 614.399 396.601 C 614.399 410.734 625.86 422.195 640.001 422.195 L 691.204 422.195 C 697.993 422.195 704.501 419.499 709.298 414.702 L 1016.501 107.499 C 1026.493 97.507 1026.501 81.312 1016.517 71.312 C 1016.509 71.312 1016.501 71.304 1016.501 71.296 Z M 1016.501 71.296" }, null, -1),
    _createElementVNode("path", { d: "M 691.204 473.398 L 640.001 473.398 C 597.602 473.351 563.243 438.992 563.196 396.601 L 563.196 345.398 C 563.149 325.023 571.251 305.468 585.696 291.101 L 761.798 114.999 L 128 114.999 C 57.336 115.077 0.078 172.335 0 242.999 L 0 703.796 C 0.078 774.461 57.336 831.726 128 831.797 L 358.399 831.797 L 358.399 959.797 C 358.399 973.937 369.86 985.398 384 985.398 C 389.539 985.398 394.93 983.601 399.36 980.273 L 597.36 831.797 L 844.798 831.797 C 915.462 831.718 972.727 774.453 972.798 703.796 L 972.798 223.601 L 745.501 450.898 C 731.126 465.351 711.579 473.445 691.204 473.398 Z M 179.203 268.601 L 435.204 268.601 C 449.336 268.601 460.797 280.062 460.797 294.202 C 460.797 308.335 449.336 319.796 435.204 319.796 L 179.203 319.796 C 165.063 319.796 153.602 308.343 153.602 294.202 C 153.602 280.062 165.063 268.601 179.203 268.601 Z M 179.203 422.195 L 435.204 422.195 C 449.336 422.195 460.797 433.656 460.797 447.796 C 460.797 461.937 449.336 473.398 435.204 473.398 L 179.203 473.398 C 165.063 473.398 153.602 461.937 153.602 447.796 C 153.602 433.663 165.063 422.195 179.203 422.195 Z M 819.196 601.398 C 819.196 615.539 807.735 627 793.602 627 L 179.203 627 C 165.063 627 153.602 615.539 153.602 601.398 C 153.602 587.265 165.063 575.796 179.203 575.796 L 793.602 575.796 C 807.735 575.796 819.196 587.257 819.196 601.398 Z M 819.196 601.398" }, null, -1)
  ])))
}
export default { render: render }