import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "ec-91b19866-4e9b-4621-96ff-c5214baaee95-___03_Login",
      "data-name": "03 Login",
      transform: "matrix(2, 0, 0, 2, 0, 0)"
    }, [
      _createElementVNode("path", { d: "m256 512a25 25 0 0 1 -25-25v-462a25 25 0 0 1 50 0v462a25 25 0 0 1 -25 25z" }),
      _createElementVNode("path", { d: "m487 281h-462a25 25 0 0 1 0-50h462a25 25 0 0 1 0 50z" })
    ], -1)
  ])))
}
export default { render: render }