import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-664c1d1a-3254-4ffe-b419-7ed1fe06049c-__Glyph",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { transform: "matrix(36.57143, 0, 0, 36.57143, -73.114769, -73.142883)" }, [
      _createElementVNode("path", { d: "m24.83 6.36h-13.29a3.00882 3.00882 0 0 0 -3 3v17.64a3.00886 3.00886 0 0 0 3 3h13.29a3.00242 3.00242 0 0 0 3-3v-17.64a3.00237 3.00237 0 0 0 -3-3z" }),
      _createElementVNode("path", { d: "m21.32422 3a.99974.99974 0 0 0 -1-1h-13.02539a3.13394 3.13394 0 0 0 -3.13037 3.13037v17.37647a1 1 0 0 0 2 0v-17.37647a1.13165 1.13165 0 0 1 1.13037-1.13037h13.02539a.99974.99974 0 0 0 1-1z" })
    ], -1)
  ])))
}
export default { render: render }