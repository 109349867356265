import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1611112596829",
  class: "ec-82c2b421-b8e9-451c-a67b-19a46f1da35d-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "2108",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, null, -1),
    _createElementVNode("path", {
      d: "M 256 999.619 L 963.048 999.619 C 969.78 999.619 975.238 994.161 975.238 987.428 L 975.238 268.19 L 768 268.19 C 747.837 268.19 731.429 251.782 731.429 231.619 L 731.429 24.381 L 256 24.381 C 249.268 24.381 243.81 29.839 243.81 36.571 L 243.81 987.428 C 243.81 994.161 249.268 999.619 256 999.619 Z",
      fill: "#FFFFFF",
      "p-id": "2109"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 970.191 243.809 L 755.81 29.428 L 755.81 231.619 C 755.81 238.352 761.268 243.809 768 243.809 L 970.191 243.809 Z",
      fill: "#FFFFFF",
      "p-id": "2110"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 988.904 228.047 L 771.572 10.715 C 764.732 3.831 755.421 -0.028 745.716 0 L 256 0 C 235.837 0 219.429 16.408 219.429 36.571 L 219.429 987.428 C 219.429 1007.591 235.837 1024 256 1024 L 963.048 1024 C 983.211 1024 999.619 1007.591 999.619 987.428 L 999.619 253.903 C 999.619 244.151 995.804 234.959 988.904 228.047 Z M 755.81 29.428 L 970.191 243.809 L 768 243.809 C 761.268 243.809 755.81 238.352 755.81 231.619 L 755.81 29.428 Z M 963.048 999.619 L 256 999.619 C 249.268 999.619 243.81 994.161 243.81 987.428 L 243.81 36.571 C 243.81 29.839 249.268 24.381 256 24.381 L 731.429 24.381 L 731.429 231.619 C 731.429 251.782 747.837 268.19 768 268.19 L 975.238 268.19 L 975.238 987.428 C 975.238 994.161 969.78 999.619 963.048 999.619 Z",
      fill: "#605E5C",
      opacity: ".64",
      "p-id": "2111"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 889.905 682.667 L 548.572 682.667 L 548.572 707.048 L 889.905 707.048 C 899.289 707.048 905.154 696.889 900.462 688.762 C 898.285 684.99 894.26 682.667 889.905 682.667 Z",
      fill: "#103F91",
      "p-id": "2112"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 889.905 609.524 L 548.572 609.524 L 548.572 633.905 L 889.905 633.905 C 899.289 633.905 905.154 623.746 900.462 615.619 C 898.285 611.847 894.26 609.524 889.905 609.524 Z",
      fill: "#185ABD",
      "p-id": "2113"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 548.572 560.762 L 889.905 560.762 C 899.289 560.762 905.154 550.603 900.462 542.476 C 898.285 538.704 894.26 536.381 889.905 536.381 L 548.572 536.381 L 548.572 560.762 Z",
      fill: "#2B7CD3",
      "p-id": "2114"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 889.905 463.238 L 548.572 463.238 L 548.572 487.619 L 889.905 487.619 C 899.289 487.619 905.154 477.46 900.462 469.333 C 898.285 465.562 894.26 463.238 889.905 463.238 Z",
      fill: "#41A5EE",
      "p-id": "2115"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 73.143 828.952 L 463.238 828.952 C 490.169 828.952 512 807.121 512 780.19 L 512 390.095 C 512 363.165 490.169 341.333 463.238 341.333 L 73.143 341.333 C 46.213 341.333 24.381 363.165 24.381 390.095 L 24.381 780.19 C 24.381 807.121 46.213 828.952 73.143 828.952 Z",
      fill: "#185ABD",
      "p-id": "2116"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 190.477 666.148 C 191.281 672.512 191.805 678.059 192.061 682.801 L 192.976 682.801 C 194.094 671.926 195.832 661.124 198.181 650.447 L 241.262 463.238 L 296.973 463.238 L 341.541 647.643 C 343.772 656.75 345.637 668.331 347.112 682.423 L 347.856 682.423 C 348.477 672.707 350.025 661.48 352.5 648.765 L 388.157 463.238 L 438.857 463.238 L 376.467 731.428 L 317.221 731.428 L 274.688 553.764 C 273.457 548.644 272.055 541.976 270.507 533.748 C 269.316 527.806 268.356 521.82 267.63 515.803 L 266.899 515.803 C 266.399 520.168 265.436 526.653 264.009 535.247 C 262.988 541.628 261.842 547.989 260.572 554.325 L 220.648 731.428 L 160.476 731.428 L 97.524 463.238 L 149.151 463.238 L 187.965 650.825 C 188.831 654.689 189.672 659.797 190.477 666.161 Z",
      fill: "#F9F7F7",
      "p-id": "2117"
    }, null, -1)
  ])))
}
export default { render: render }