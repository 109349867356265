import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1662425555307",
  class: "ec-3736ba91-a9df-4320-93f2-558471671260-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "2415",
  width: "24",
  height: "24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M174.8 803.9h685.8c23.4 0 42.9-23.4 39-46.8L884 671.4c-7.8-35.1-39-62.3-74-62.3H623l-15.6-97.4c85.7-35.1 144.2-116.9 144.2-214.3 0-128.6-105.2-233.8-233.8-233.8S283.9 168.7 283.9 297.3c0 97.4 58.4 179.2 144.2 214.3L416.4 609h-187c-35.1 0-70.1 27.3-74 62.3L139.8 757c-7.9 23.5 11.6 46.9 35 46.9z m693.6 77.9H167c-23.4 0-39 15.6-39 39s15.6 39 39 39h701.4c23.4 0 39-15.6 39-39s-15.7-39-39-39z",
      "p-id": "2416",
      style: {"fill":"rgb(13, 70, 160)"}
    }, null, -1)
  ])))
}
export default { render: render }