import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-f7716e8d-5cab-47ce-921c-19df5c83cdfa-__uuid-42e99c6f-19e3-4e26-be80-acea88b58f35",
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".ec-9557fcf0-ffb4-4e36-9ba0-e1656577072a-__uuid-974268cf-1fbb-4a30-81fa-9d5b71ae4b47{fill:none}.ec-e96b2d27-9894-4284-81c3-377be9ca7acf-__uuid-f9e1ab07-6037-4394-92eb-1dab1a0891bd{fill:#fff}.ec-5350c8ba-fccf-4da3-a9b7-5d878af810c0-__uuid-0ec83c87-85b5-4f57-b2ff-141c8ee85f3a{fill:#2196f3;fill-rule:evenodd}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      class: "ec-5350c8ba-fccf-4da3-a9b7-5d878af810c0-__uuid-0ec83c87-85b5-4f57-b2ff-141c8ee85f3a",
      d: "M 512 1024 C 229.28 1024 0 794.72 0 512 C 0 229.28 229.28 0 512 0 C 794.72 0 1024 229.28 1024 512 C 1024 794.72 794.72 1024 512 1024 Z"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      class: "ec-e96b2d27-9894-4284-81c3-377be9ca7acf-__uuid-f9e1ab07-6037-4394-92eb-1dab1a0891bd",
      d: "M 356.427 670.267 C 331.28 647.627 313.147 620.507 301.28 591.36 C 269.76 514.08 284.533 422.16 346.053 358.747 L 402.827 415.52 C 405.947 418.64 411.573 416.4 412.32 411.76 L 443.333 208.8 C 443.84 205.413 440.96 202.56 437.573 203.04 L 234.48 234.053 C 229.84 234.8 227.6 240.427 230.72 243.547 L 287.627 300.453 C 193.707 396.507 177.707 540.56 239.36 653.12 C 257.867 687.013 283.52 718.027 316.16 744.027 C 371.68 788.187 439.973 809.307 507.76 807.92 L 520.507 724.507 C 462.107 729.52 402.08 711.493 356.427 670.24 L 356.427 670.267 Z M 356.427 670.267"
    }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("path", {
      class: "ec-e96b2d27-9894-4284-81c3-377be9ca7acf-__uuid-f9e1ab07-6037-4394-92eb-1dab1a0891bd",
      d: "M 734.107 720.427 C 828.027 624.373 844.027 480.32 782.373 367.76 C 763.867 333.867 738.213 302.853 705.573 276.853 C 650.053 232.693 581.76 211.573 513.973 212.96 L 501.227 296.373 C 559.493 291.36 619.653 309.387 665.307 350.64 C 690.453 373.28 708.587 400.427 720.453 429.547 C 751.973 506.827 737.2 598.747 675.68 662.16 L 618.907 605.387 C 615.787 602.267 610.16 604.507 609.413 609.147 L 578.4 812.107 C 577.893 815.493 580.773 818.347 584.16 817.867 L 787.253 786.853 C 791.867 786.107 794 780.48 791.013 777.333 L 734.107 720.427 Z M 734.107 720.427"
    }, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("rect", {
      class: "ec-9557fcf0-ffb4-4e36-9ba0-e1656577072a-__uuid-974268cf-1fbb-4a30-81fa-9d5b71ae4b47",
      x: "202.667",
      y: "202.24",
      width: "616.96",
      height: "616.96"
    }, null, -1))
  ]))
}
export default { render: render }