import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1626230828766",
  class: "ec-c6c58ab6-206b-4b9b-8d8f-6329e08aca0e-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "3882",
  width: "64",
  height: "64",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 967.111 281.6 L 967.111 910.222 C 967.111 973.085 916.196 1024 853.333 1024 L 170.667 1024 C 107.804 1024 56.889 973.085 56.889 910.222 L 56.889 113.778 C 56.889 50.915 107.804 0 170.667 0 L 685.511 0 L 967.111 281.6 Z",
      fill: "#FFC63A",
      "p-id": "3883"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 685.511 167.822 L 685.511 0 L 967.111 281.6 L 799.289 281.6 C 736.427 281.6 685.511 230.685 685.511 167.822",
      fill: "#DD9F08",
      "p-id": "3884"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 436.565 68.437 L 505.002 68.437 L 505.002 0 L 436.565 0 Z M 505.002 136.875 L 573.441 136.875 L 573.441 68.437 L 505.002 68.437 Z M 436.565 205.312 L 505.002 205.312 L 505.002 136.875 L 436.565 136.875 Z M 505.002 273.75 L 573.441 273.75 L 573.441 205.312 L 505.002 205.312 Z",
      fill: "#FFFFFF",
      "p-id": "3885"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 436.565 342.158 L 505.002 342.158 L 505.002 273.722 L 436.565 273.722 Z M 505.002 410.624 L 573.441 410.624 L 573.441 342.186 L 505.002 342.186 Z",
      fill: "#FFFFFF",
      "p-id": "3886"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 436.565 479.033 L 505.002 479.033 L 505.002 410.596 L 436.565 410.596 Z M 505.002 547.471 L 573.441 547.471 L 573.441 479.033 L 505.002 479.033 Z M 470.784 762.226 L 539.221 762.226 L 539.221 693.79 L 470.784 693.79 L 470.784 762.226 Z M 436.565 625.351 L 436.565 762.226 C 436.565 781.142 451.897 796.444 470.784 796.444 L 539.221 796.444 C 558.137 796.444 573.441 781.142 573.441 762.226 L 573.441 625.351 L 436.565 625.351 Z",
      fill: "#FFFFFF",
      "p-id": "3887"
    }, null, -1)
  ])))
}
export default { render: render }