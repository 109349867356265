import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 26.56 962.783 C 10.666 957.309 -0.002 942.35 0 925.54 C 0 745.6 36.136 601.698 107.447 497.796 C 193.601 372.258 329.503 304.663 512.002 296.27 L 512.002 98.46 C 512.004 68.141 544.827 49.195 571.082 64.357 C 573.799 65.925 576.318 67.813 578.587 69.98 L 1011.819 483.519 C 1028.06 499.033 1028.06 524.966 1011.819 540.48 L 578.587 954.02 C 556.66 974.958 520.289 964.309 513.119 934.85 C 512.377 931.802 512.002 928.677 512.002 925.54 L 512.002 729.182 C 401.232 732.53 317.54 750.474 250.906 784.911 C 178.905 822.13 126.696 877.736 70.425 949.835 C 60.076 963.086 42.457 968.307 26.56 962.832 Z" }, null, -1)
  ])))
}
export default { render: render }