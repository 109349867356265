import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 648.533 682.667 L 810.667 682.667 C 928 682.667 1024 637.419 1024 521.387 C 1024 405.355 910.933 364.651 819.2 360.107 C 800.235 178.646 667.733 68.267 512 68.267 C 364.8 68.267 269.995 165.952 238.933 262.827 C 110.933 274.987 0 339.371 0 472.747 C 0 606.123 115.2 682.667 256 682.667 L 375.467 682.667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "32"
    }, null, -1),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "32",
      d: "M 375.467 819.414 L 512 955.734 L 648.533 819.414"
    }, null, -1),
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "32",
      d: "M 512 443.734 L 512 921.664"
    }, null, -1)
  ])))
}
export default { render: render }