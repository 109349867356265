import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "ec-ac07dd90-9bcd-4d72-b873-d2b9d4be58ac-__Flat",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(2.206896, 0, 0, 2.206896, -97.103317, -52.965427)\"><path d=\"m384 40h32v432h-32z\" fill=\"#127bb3\"></path><path d=\"m80 24h312v448h-312z\" fill=\"#25b1fa\"></path><g fill=\"#b3e9ff\"><path d=\"m112 56h112v120h-112z\"></path><path d=\"m248 128h120v16h-120z\"></path><path d=\"m248 168h120v16h-120z\"></path><path d=\"m104 208h80v16h-80z\"></path><path d=\"m200 208h128v16h-128z\"></path><path d=\"m104 248h176v16h-176z\"></path><path d=\"m104 288h80v16h-80z\"></path><path d=\"m200 288h96v16h-96z\"></path><path d=\"m296 328h24v16h-24z\"></path><path d=\"m104 328h176v16h-176z\"></path><path d=\"m256 56h104v40h-104z\"></path><rect height=\"64\" rx=\"32\" width=\"64\" x=\"112\" y=\"376\"></rect></g><rect fill=\"#999\" height=\"64\" rx=\"32\" width=\"64\" x=\"208\" y=\"376\"></rect><rect fill=\"#b3e9ff\" height=\"64\" rx=\"32\" width=\"64\" x=\"208\" y=\"376\"></rect><path d=\"m376 488v-128l-72-96 8.765-7.012a40 40 0 0 1 56.223 6.247l7.012 8.765h16v-128h10.377a30 30 0 0 1 28.461 20.513l41.162 123.487v16l-24 64v120z\" fill=\"#ffd4cf\"></path><path d=\"m296 384h64v16h-64z\" fill=\"#b3e9ff\"></path><path d=\"m296 416h16v16h-16z\" fill=\"#b3e9ff\"></path><path d=\"m328 416h32v16h-32z\" fill=\"#b3e9ff\"></path></g>", 1)
  ])))
}
export default { render: render }