import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 -32 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 929.742 32.262 L 479.859 32.262 C 427.797 32.262 385.602 74.465 385.602 126.52 L 385.602 348.121 C 409.383 359.942 435.078 372.723 459.063 384.645 L 495.758 311.223 C 517.977 266.825 572.141 248.785 616.523 270.965 C 661 293.223 679.023 347.239 656.758 391.723 L 591.344 522.582 C 593.078 539.903 591.539 557.364 586.656 574.262 L 629.844 574.262 L 515.938 887.481 C 509.18 906.059 522.242 927.739 544.141 927.739 C 556.383 927.739 567.898 920.184 572.32 907.981 L 693.68 574.262 L 714.703 574.262 L 837.297 908.082 C 841.758 920.223 853.242 927.739 865.477 927.739 C 885.938 927.739 900.938 907.301 893.617 887.403 L 778.617 574.262 L 929.742 574.262 C 981.797 574.262 1024 532.059 1024 480.004 L 1024 126.52 C 1024 74.465 981.797 32.262 929.742 32.262 Z M 865.461 445.739 L 730.859 445.739 C 714.297 445.739 700.859 432.301 700.859 415.739 C 700.859 399.16 714.297 385.739 730.859 385.739 L 865.461 385.739 C 882.039 385.739 895.461 399.16 895.461 415.739 C 895.461 432.301 882.039 445.739 865.461 445.739 Z M 865.461 333.262 L 730.859 333.262 C 714.297 333.262 700.859 319.84 700.859 303.262 C 700.859 286.7 714.297 273.262 730.859 273.262 L 865.461 273.262 C 882.039 273.262 895.461 286.7 895.461 303.262 C 895.461 319.84 882.039 333.262 865.461 333.262 Z M 865.461 220.801 L 544.141 220.801 C 527.563 220.801 514.141 207.364 514.141 190.801 C 514.141 174.239 527.563 160.801 544.141 160.801 L 865.461 160.801 C 882.039 160.801 895.461 174.239 895.461 190.801 C 895.461 207.364 882.039 220.801 865.461 220.801 Z M 865.461 220.801" }, null, -1),
    _createElementVNode("path", { d: "M 527.844 561.395 C 533.266 545.512 533.227 528.918 528.648 513.778 L 603.102 364.887 C 610.508 350.067 604.508 332.043 589.688 324.637 C 574.875 317.223 556.852 323.231 549.438 338.051 L 485.938 465.035 C 447.984 446.137 390.32 417.465 343.867 394.364 C 300.648 372.871 269.805 353.598 217.18 353.598 L 190.672 353.598 C 231.648 353.598 268.219 334.739 292.156 305.223 C 310.422 282.762 321.344 254.137 321.344 222.942 C 321.344 150.762 262.844 92.262 190.656 92.262 C 118.5 92.262 60 150.762 60 222.942 C 60 257.606 73.5 289.106 95.523 312.481 C 119.336 337.801 153.156 353.598 190.648 353.606 C 140.383 353.606 91.242 373.973 55.891 409.418 C 19.852 445.364 0 493.254 0 544.27 L 0 640.668 C 0 682.168 26.945 717.481 64.266 730.043 L 64.266 897.731 C 64.266 914.301 77.695 927.731 94.266 927.731 L 287.063 927.731 C 303.633 927.731 317.063 914.301 317.063 897.731 L 317.063 560.66 C 345.508 574.879 382.875 593.559 413.109 608.684 C 434.305 619.278 459.07 620.231 481.156 611.246 C 503.18 602.184 520.156 584.145 527.727 561.746 C 527.766 561.629 527.805 561.512 527.844 561.395 Z M 527.844 561.395" }, null, -1)
  ])))
}
export default { render: render }