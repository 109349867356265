import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "ec-d075c23c-3494-433c-8ae1-26118d277cac-__Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(1.953125, 0, 0, 1.953125, 11.999969, 0)\"><path d=\"m301 46c0-24.814-20.186-46-45-46s-45 21.186-45 46 20.186 45 45 45 45-20.186 45-45z\"></path><path d=\"m331 166c0-41.353-33.647-75-75-75s-75 33.647-75 75v15h150z\"></path><circle cx=\"436\" cy=\"226\" r=\"45\"></circle><path d=\"m436 271c-41.353 0-75 33.647-75 75v15h151v-15c0-41.353-34.647-75-76-75z\"></path><circle cx=\"76\" cy=\"226\" r=\"45\"></circle><path d=\"m151 346c0-41.353-33.647-75-75-75s-76 33.647-76 75v15h151z\"></path><circle cx=\"256\" cy=\"376\" r=\"45\"></circle><path d=\"m331 496c0-41.353-33.647-75-75-75s-75 33.647-75 75v16h150z\"></path><path d=\"m91 136c0-24.814 20.186-45 45-45h15v-30h-15c-41.353 0-75 33.647-75 75v15h30z\"></path><path d=\"m421 136v15h30v-15c0-41.353-33.647-75-75-75h-15v30h15c24.814 0 45 20.186 45 45z\"></path><path d=\"m61 391v15c0 41.353 33.647 75 75 75h15v-30h-15c-24.814 0-45-20.186-45-45v-15z\"></path><path d=\"m451 406v-15h-30v15c0 24.814-20.186 45-45 45h-15v30h15c41.353 0 75-33.647 75-75z\"></path></g>", 1)
  ])))
}
export default { render: render }