import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "ec-5ca7314c-1252-47b8-b4aa-1c0c5dc37d12-__Layer_18",
      "data-name": "Layer 18",
      transform: "matrix(17.37746, 0, 0, 17.37746, -44.146774, -44.190296)"
    }, [
      _createElementVNode("path", { d: "m60.08 53.34-16-16a22.57 22.57 0 1 0 -6.74 6.74l16 16a4.76 4.76 0 1 0 6.73-6.74zm-48.82-14.34a19.58 19.58 0 1 1 27.74 0 19.6 19.6 0 0 1 -27.74 0zm46.74 19a1.76 1.76 0 0 1 -2.49 0l-15.7-15.7c.44-.38 2.11-2 2.49-2.5l15.7 15.66a1.77 1.77 0 0 1 0 2.54z" })
    ], -1)
  ])))
}
export default { render: render }