import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M480 265H232v179l248 36V265z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M216 265H32v150l184 26.7V265z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M480 32L232 67.4V249h248V32z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M216 69.7L32 96v153h184V69.7z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }