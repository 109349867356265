import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1678787246161",
  class: "ec-351fe82f-e85e-4636-8487-648e37409726-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "2766",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 995.657 173.296 C 979.054 157.726 952.87 158.47 937.3 175.114 L 386.52 761.535 L 85.649 465.083 C 69.377 449.058 43.233 449.223 27.209 465.496 C 11.185 481.727 11.391 507.911 27.663 523.895 L 358.684 850 C 358.808 850.125 358.973 850.166 359.097 850.29 C 359.221 850.414 359.263 850.579 359.386 850.702 C 361.988 853.181 365.127 854.585 368.142 856.237 C 369.629 857.104 370.909 858.385 372.479 859.004 C 377.352 860.904 382.514 861.895 387.636 861.895 C 393.046 861.895 398.456 860.822 403.494 858.674 C 405.188 857.972 406.51 856.526 408.079 855.618 C 411.177 853.8 414.398 852.273 417.041 849.588 C 417.166 849.464 417.206 849.257 417.33 849.133 C 417.413 849.01 417.578 848.969 417.702 848.844 L 997.433 231.655 C 1013.127 215.093 1012.26 188.949 995.657 173.296 Z",
      "p-id": "2767",
      fill: "#70B87E",
      style: {}
    }, null, -1)
  ])))
}
export default { render: render }