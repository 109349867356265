import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1626226787454",
  class: "ec-9382fb13-054b-419e-8e82-b0f40f43a212-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "2041",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 700.523 0 L 130.901 0 C 120.288 0 111.605 10.286 111.605 22.857 L 111.605 1001.143 C 111.605 1013.714 120.288 1024 130.901 1024 L 893.099 1024 C 903.711 1024 912.395 1013.714 912.395 1001.143 L 912.395 237.257 L 700.523 0 Z M 873.802 260.914 L 873.802 971.429 C 873.802 975.2 871.197 978.286 868.013 978.286 L 155.986 978.286 C 152.803 978.286 150.197 975.2 150.197 971.429 L 150.197 52.571 C 150.197 48.8 152.803 45.714 155.986 45.714 L 683.542 45.714 L 873.802 260.914 Z",
      "p-id": "2042",
      style: {}
    }, null, -1),
    _createElementVNode("path", {
      d: "M 227.382 589.371 L 796.618 589.371 L 796.618 635.086 L 227.382 635.086 Z M 227.382 404.8 L 796.618 404.8 L 796.618 450.514 L 227.382 450.514 Z M 227.382 772.343 L 796.618 772.343 L 796.618 818.057 L 227.382 818.057 Z M 227.382 206.514 L 681.323 206.514 L 681.323 252.229 L 227.382 252.229 Z",
      "p-id": "2043",
      style: {}
    }, null, -1)
  ])))
}
export default { render: render }