import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "ec-bcda86bc-cf19-489b-a111-3617c3970390-__Capa_1",
  "enable-background": "new 0 0 512.02 512.02",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(1.999922, 0, 0, 2, 0, -0.01)\"><path d=\"m301.66.01v91.46h91.01z\"></path><path d=\"m392.67 121.47h-121.01v-121.46h-271.66v512h271.66v-51.06l121.01-121.56zm-248.78 321.53h-100.17v-30h100.17zm-64.48-90.51-40.54-40.54 21.21-21.21 19.33 19.33 48.12-48.12 21.21 21.22zm0-119.74-40.54-40.54 21.21-21.21 19.33 19.32 48.12-48.11 21.21 21.22zm249.85 80.14h-141.62v-30h141.62zm0-115.14h-141.62v-30h141.62z\"></path><path d=\"m419.01 355.33c-21.679 21.788-94.543 95.064-117.35 118v38.68h38.63l117.3-118.1z\"></path><path d=\"m474.21 299.82-34.05 34.24 38.57 38.57 33.29-33.51z\"></path></g>", 1)
  ])))
}
export default { render: render }