import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1611199670497",
  class: "ec-6a9da3d7-9dac-4ea3-bf30-c54532ec7b44-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "6586",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, null, -1),
    _createElementVNode("path", {
      d: "M 384.039 186.148 L 375.652 177.761 C 364.748 166.857 350.49 160.986 335.392 160.986 L 164.289 160.986 C 133.255 160.986 107.254 186.148 107.254 218.021 L 107.254 807.657 C 107.254 838.69 132.416 864.691 164.289 864.691 L 958.578 864.691 C 989.611 864.691 1015.612 839.529 1015.612 807.657 L 1015.612 259.119 C 1015.612 228.086 990.45 202.085 958.578 202.085 L 424.299 202.085 C 409.202 202.923 394.943 197.052 384.039 186.148 Z",
      fill: "#F1ED7B",
      "p-id": "6587"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 384.039 186.148 L 375.652 177.761 C 364.748 166.857 350.49 160.986 335.392 160.986 L 164.289 160.986 C 133.255 160.986 107.254 186.148 107.254 218.021 L 107.254 807.657 C 107.254 838.69 132.416 864.691 164.289 864.691 L 958.578 864.691 C 989.611 864.691 1015.612 839.529 1015.612 807.657 L 1015.612 259.119 C 1015.612 228.086 990.45 202.085 958.578 202.085 L 424.299 202.085 C 409.202 202.923 394.943 197.052 384.039 186.148 Z",
      fill: "#0E1115",
      "p-id": "6588"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 957.739 872.24 L 164.289 872.24 C 128.223 872.24 98.867 842.884 98.867 806.818 L 98.867 217.182 C 98.867 181.116 128.223 151.76 164.289 151.76 L 335.392 151.76 C 353.006 151.76 368.942 158.47 381.523 171.051 L 389.91 179.439 C 399.137 188.665 410.879 193.697 424.299 193.697 L 958.578 193.697 C 994.644 193.697 1024 223.053 1024 259.119 L 1024 806.818 C 1023.161 842.884 993.805 872.24 957.739 872.24 Z M 164.289 169.374 C 137.449 169.374 115.642 191.181 115.642 218.021 L 115.642 807.657 C 115.642 834.497 137.449 856.304 164.289 856.304 L 958.578 856.304 C 985.418 856.304 1007.225 834.497 1007.225 807.657 L 1007.225 259.119 C 1007.225 232.279 985.418 210.472 958.578 210.472 L 424.299 210.472 C 406.685 210.472 390.749 203.762 378.168 191.181 L 369.781 182.793 C 360.554 173.567 348.812 168.535 335.392 168.535 L 164.289 168.535 Z",
      fill: "#6A576D",
      "p-id": "6589"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 192.806 244.86 L 930.061 244.86 C 961.094 244.86 987.095 270.023 987.095 301.895 L 987.095 773.268 C 987.095 804.302 961.933 830.303 930.061 830.303 L 192.806 830.303 C 161.772 830.303 135.771 805.141 135.771 773.268 L 135.771 301.895 C 135.771 270.861 160.934 244.86 192.806 244.86 Z",
      fill: "#FFFFFF",
      "p-id": "6590"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 930.061 838.69 L 192.806 838.69 C 156.74 838.69 127.384 809.334 127.384 773.268 L 127.384 301.895 C 127.384 265.829 156.74 236.473 192.806 236.473 L 930.061 236.473 C 966.127 236.473 995.483 265.829 995.483 301.895 L 995.483 773.268 C 994.644 809.334 965.288 838.69 930.061 838.69 Z M 192.806 253.248 C 165.966 253.248 144.159 275.055 144.159 301.895 L 144.159 773.268 C 144.159 800.108 165.966 821.915 192.806 821.915 L 930.061 821.915 C 956.901 821.915 978.708 800.108 978.708 773.268 L 978.708 301.895 C 978.708 275.055 956.901 253.248 930.061 253.248 L 192.806 253.248 Z",
      fill: "#6A576D",
      "p-id": "6591"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 135.771 852.949 L 95.512 352.219 C 92.996 319.509 118.997 290.991 151.708 290.991 L 888.962 290.991 C 918.318 290.991 943.481 313.637 945.158 342.993 L 986.256 852.949",
      fill: "#FFFFFF",
      "p-id": "6592"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 986.256 861.336 C 982.063 861.336 977.869 857.981 977.869 853.788 L 936.771 343.832 C 935.093 318.67 913.286 299.379 888.962 299.379 L 151.708 299.379 C 138.288 299.379 125.707 305.25 116.48 315.315 C 107.254 325.38 103.061 337.961 103.899 352.219 L 144.159 852.949 C 144.159 857.143 140.804 861.336 136.61 862.175 C 132.416 862.175 128.223 858.82 127.384 854.626 L 87.124 353.897 C 85.447 335.445 92.157 317.831 103.899 304.411 C 116.48 290.991 133.255 283.443 151.708 283.443 L 888.962 283.443 C 922.512 283.443 951.029 309.444 953.546 342.993 L 994.644 852.949 C 995.483 856.304 991.289 860.498 986.256 861.336 C 987.095 861.336 986.256 861.336 986.256 861.336 Z",
      fill: "#6A576D",
      "p-id": "6593"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 135.771 863.852 L 67.833 313.637",
      fill: "#0E1115",
      "p-id": "6594"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 135.771 852.949 L 46.865 403.383 C 40.155 368.156 66.995 336.283 103.061 336.283 L 837.799 336.283 C 864.639 336.283 888.124 355.574 893.156 382.414 L 986.256 853.788",
      fill: "#FFFFFF",
      "p-id": "6595"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 986.256 861.336 C 982.063 861.336 978.708 858.82 977.869 854.626 L 884.769 383.253 C 880.575 360.607 860.445 344.671 836.96 344.671 L 103.061 344.671 C 88.802 344.671 74.543 351.381 65.317 362.284 C 56.091 373.188 52.736 388.285 55.252 402.544 L 144.159 851.271 C 144.998 855.465 142.481 860.498 137.449 861.336 C 133.255 862.175 128.223 859.659 127.384 854.626 L 38.477 405.06 C 34.284 385.769 39.316 366.478 51.897 351.381 C 64.478 336.283 82.931 327.896 102.222 327.896 L 836.96 327.896 C 867.994 327.896 894.834 349.703 900.705 380.737 L 993.805 852.11 C 994.644 856.304 992.128 861.336 987.095 862.175 C 987.095 861.336 987.095 861.336 986.256 861.336 Z",
      fill: "#6A576D",
      "p-id": "6596"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 65.317 364.801 L 870.51 364.801 C 897.35 364.801 919.996 383.253 925.867 409.254 L 1013.096 795.076 C 1021.484 830.303 993.805 864.691 957.739 864.691 L 152.546 864.691 C 125.707 864.691 103.061 846.239 97.189 820.238 L 9.96 434.416 C 1.573 398.35 28.412 364.801 65.317 364.801 Z",
      fill: "#F1ED7B",
      "p-id": "6597"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 957.739 872.24 L 152.546 872.24 C 122.352 872.24 95.512 851.271 88.802 821.915 L 1.573 436.094 C -2.621 416.803 1.573 396.673 14.154 380.737 C 26.735 364.801 45.187 356.413 65.317 356.413 L 870.51 356.413 C 900.705 356.413 927.545 377.382 934.254 406.738 L 1021.484 792.559 C 1025.677 811.85 1021.484 831.98 1008.903 847.916 C 996.321 863.852 977.869 872.24 957.739 872.24 Z M 65.317 373.188 C 50.22 373.188 36.8 379.898 27.574 391.64 C 18.347 403.383 14.993 418.48 18.347 432.739 L 105.577 818.56 C 110.609 840.368 129.9 856.304 152.546 856.304 L 957.739 856.304 C 972.837 856.304 986.256 849.594 995.483 837.851 C 1004.709 826.109 1008.064 811.012 1004.709 796.753 L 917.48 410.931 C 912.447 389.124 893.156 373.188 870.51 373.188 L 65.317 373.188 Z",
      fill: "#6A576D",
      "p-id": "6598"
    }, null, -1)
  ])))
}
export default { render: render }