import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 394.664 0 L 74.664 0 C 33.492 0 0 33.492 0 74.664 L 0 266.664 C 0 307.844 33.492 341.336 74.664 341.336 L 394.664 341.336 C 435.844 341.336 469.336 307.844 469.336 266.664 L 469.336 74.664 C 469.336 33.492 435.844 0 394.664 0 Z M 394.664 0" }, null, -1),
    _createElementVNode("path", { d: "M 394.664 426.664 L 74.664 426.664 C 33.492 426.664 0 460.156 0 501.336 L 0 949.336 C 0 990.508 33.492 1024 74.664 1024 L 394.664 1024 C 435.844 1024 469.336 990.508 469.336 949.336 L 469.336 501.336 C 469.336 460.156 435.844 426.664 394.664 426.664 Z M 394.664 426.664" }, null, -1),
    _createElementVNode("path", { d: "M 949.336 682.664 L 629.336 682.664 C 588.156 682.664 554.664 716.156 554.664 757.336 L 554.664 949.336 C 554.664 990.508 588.156 1024 629.336 1024 L 949.336 1024 C 990.508 1024 1024 990.508 1024 949.336 L 1024 757.336 C 1024 716.156 990.508 682.664 949.336 682.664 Z M 949.336 682.664" }, null, -1),
    _createElementVNode("path", { d: "M 949.336 0 L 629.336 0 C 588.156 0 554.664 33.492 554.664 74.664 L 554.664 522.664 C 554.664 563.844 588.156 597.336 629.336 597.336 L 949.336 597.336 C 990.508 597.336 1024 563.844 1024 522.664 L 1024 74.664 C 1024 33.492 990.508 0 949.336 0 Z M 949.336 0" }, null, -1)
  ])))
}
export default { render: render }