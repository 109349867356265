import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "ec-fe04e8b2-e420-4dad-8c62-da7db819191e-__icon",
  width: "48",
  height: "48",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M 379.744 501.401 L 427.837 545.92 L 645.555 345.099 C 688.274 307.137 752.638 307.137 795.358 345.099 L 999.428 533.571 L 999.428 32.495 C 999.428 14.549 984.88 0 966.933 0 L 57.066 0 C 39.12 0 24.571 14.549 24.571 32.495 L 24.571 678.501 L 215.968 501.401 C 262.651 459.845 333.06 459.845 379.744 501.401 Z\" fill=\"#FFFFFF\"></path><path d=\"M 57.066 982.171 L 174.699 982.171 L 427.837 748.206 L 379.744 703.687 C 333.266 662.896 263.746 662.896 217.268 703.687 L 24.571 880.786 L 24.571 949.677 C 24.571 967.623 39.12 982.171 57.066 982.171 Z M 645.555 547.385 L 427.837 748.206 L 681.3 982.171 L 966.933 982.171 C 984.88 982.171 999.428 967.623 999.428 949.677 L 999.428 735.857 L 795.358 547.385 C 752.638 509.423 688.274 509.423 645.555 547.385 Z\" fill=\"#99BF87\"></path><path d=\"M 174.699 982.171 L 681.3 982.171 L 427.837 748.206 L 174.699 982.171 Z\" fill=\"#99BF87\"></path><path d=\"M 966.933 42.665 L 966.933 981.335 L 57.066 981.335 L 57.066 42.665 L 966.933 42.665 M 966.933 0 L 57.066 0 C 39.12 0 24.571 19.102 24.571 42.665 L 24.571 981.335 C 24.571 1004.898 39.12 1024 57.066 1024 L 966.933 1024 C 984.88 1024 999.428 1004.898 999.428 981.335 L 999.428 42.665 C 999.428 19.102 984.88 0 966.933 0 Z\" fill=\"#4D4D4D\" style=\"\"></path><path d=\"M 254.076 248.934 M 156.59 248.934 C 156.59 323.978 237.828 370.881 302.819 333.359 C 332.981 315.944 351.562 283.761 351.562 248.934 C 351.562 173.889 270.323 126.986 205.333 164.509 C 175.171 181.922 156.59 214.105 156.59 248.934 Z\" fill=\"#F4CB5A\"></path>", 5)
  ])))
}
export default { render: render }