import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "clip-rule": "evenodd",
  "fill-rule": "evenodd",
  height: "48px",
  "image-rendering": "optimizeQuality",
  "shape-rendering": "geometricPrecision",
  "text-rendering": "geometricPrecision",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "ec-a027657b-6bcb-479f-bbe1-b52d59f62b09-__图层_x0020_1",
      transform: "matrix(0.004031, 0, 0, 0.004031, 0.062975, 0.062975)"
    }, [
      _createElementVNode("path", {
        d: "m127000 254000c-70129 0-127000-56871-127000-127000s56871-127000 127000-127000 127000 56871 127000 127000-56871 127000-127000 127000zm-17473-104654 76212-76211c2637-2638 6967-2611 9578 0l8642 8642c2611 2611 2611 6968 0 9578l-89509 89510c-1601 1601-3850 2231-5957 1869-1442-181-2833-814-3919-1899l-54525-54525c-2606-2606-2637-6941 0-9579l8642-8642c2638-2637 6941-2637 9579 0z",
        fill: "#48b02c"
      })
    ], -1)
  ])))
}
export default { render: render }