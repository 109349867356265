import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1662426528750",
  class: "ec-e92135f6-353f-431e-9fd0-10cfc4db7e4d-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "4586",
  id: "ec-ff748939-4bea-484e-86d2-9f0ce89b6c8d-__mx_n_1662426528752",
  width: "24",
  height: "24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M582.4 990.7H152c-83.8 0-152-68.2-152-152V185.3c0-83.8 68.2-152 152-152h601c83.8 0 152 68.2 152 152v134.2c0 17.7-14.3 32-32 32s-32-14.3-32-32V185.3c0-48.5-39.5-88-88-88H152c-48.5 0-88 39.5-88 88v653.5c0 48.5 39.5 88 88 88h430.4c17.7 0 32 14.3 32 32s-14.4 31.9-32 31.9z",
      "p-id": "4587",
      style: {"fill":"rgb(13, 70, 160)"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M674.3 318.6h-480c-17.6 0-32-14.4-32-32s14.4-32 32-32h480c17.6 0 32 14.4 32 32s-14.4 32-32 32zM507.8 542.1h-314c-17.6 0-32-14.4-32-32s14.4-32 32-32h314c17.6 0 32 14.4 32 32s-14.4 32-32 32zM405.6 766.4h-212c-17.6 0-32-14.4-32-32s14.4-32 32-32h212c17.6 0 32 14.4 32 32s-14.4 32-32 32zM571.6 730.3l65.9 72.5c2.5 2.8 1.1 7.2-2.6 8.1l-95.8 20.7c-3.6 0.8-6.8-2.7-5.6-6.2l30-93.2c1-3.6 5.6-4.7 8.1-1.9zM906 571.6L676 779.7c-5.5 5-15.3 3.4-21.6-3.5L600 716.4c-6.3-7-7-16.8-1.4-21.8l230-208.1c5.5-5 15.3-3.4 21.6 3.5l54.4 59.8c6.3 6.9 6.9 16.8 1.4 21.8z m109.3-97.7l-60.2 54.7c-9.2 8.3-21.7 9.3-28.1 2.3l-54.3-59.7c-6.3-6.9-4.1-19.4 5-27.7l60.3-54.6c9.2-8.3 21.7-9.3 28.1-2.3l54.3 59.7c6.3 6.9 4.1 19.3-5.1 27.6z m0 0",
      "p-id": "4588",
      style: {"fill":"rgb(13, 70, 160)"}
    }, null, -1)
  ])))
}
export default { render: render }