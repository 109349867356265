import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      x: "109.981",
      y: "182.747",
      width: "804.038",
      height: "475.113",
      rx: "12",
      ry: "12"
    }, null, -1),
    _createElementVNode("path", { d: "M 914.018 73.105 L 548.547 73.105 L 548.547 36.558 C 548.547 8.424 518.091 -9.16 493.726 4.907 C 482.418 11.436 475.452 23.501 475.452 36.558 L 475.452 73.105 L 109.981 73.105 C 49.453 73.168 0.402 122.22 0.339 182.747 L 0.339 657.86 C 0.402 718.387 49.453 767.439 109.981 767.502 L 207.721 767.502 L 147.944 976.734 C 139.684 1003.629 163.636 1029.379 191.057 1023.085 C 204.276 1020.05 214.728 1009.945 218.206 996.835 L 283.74 767.502 L 475.452 767.502 L 475.452 877.143 C 475.452 905.277 505.908 922.861 530.273 908.794 C 541.581 902.265 548.547 890.2 548.547 877.143 L 548.547 767.502 L 740.259 767.502 L 805.793 996.835 C 813.007 1024.029 846.954 1033.215 866.897 1013.371 C 876.511 1003.804 880.037 989.7 876.055 976.734 L 816.278 767.502 L 914.018 767.502 C 974.546 767.439 1023.597 718.387 1023.66 657.86 L 1023.66 182.747 C 1023.597 122.22 974.546 73.168 914.018 73.105 Z M 950.566 657.86 C 950.566 678.045 934.203 694.407 914.018 694.407 L 109.981 694.407 C 89.796 694.407 73.433 678.045 73.433 657.86 L 73.433 182.747 C 73.433 162.562 89.796 146.2 109.981 146.2 L 914.018 146.2 C 934.203 146.2 950.566 162.562 950.566 182.747 Z" }, null, -1)
  ])))
}
export default { render: render }