import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "ec-cfa5a9f6-873b-4da4-b83d-a1fff74a8fb9-__Layer_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 1024 1024",
  style: {"enable-background":"new 0 0 512 512"},
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"><g><path d=\"M256,0C114.509,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.491,0,256-114.496,256-256 C512,114.511,397.504,0,256,0z M256,476.279c-121.462,0-220.279-98.816-220.279-220.279S134.538,35.721,256,35.721 S476.279,134.537,476.279,256S377.462,476.279,256,476.279z\" style=\"fill:rgb(51, 51, 51);\"></path></g></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"><g><path d=\"M256.006,213.397c-15.164,0-25.947,6.404-25.947,15.839v128.386c0,8.088,10.783,16.174,25.947,16.174 c14.49,0,26.283-8.086,26.283-16.174V229.234C282.289,219.8,270.496,213.397,256.006,213.397z\" style=\"fill:rgb(51, 51, 51);\"></path></g></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"><g><path d=\"M256.006,134.208c-15.501,0-27.631,11.12-27.631,23.925c0,12.806,12.131,24.263,27.631,24.263 c15.164,0,27.296-11.457,27.296-24.263C283.302,145.328,271.169,134.208,256.006,134.208z\" style=\"fill:rgb(51, 51, 51);\"></path></g></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g style=\"\" transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g>", 18)
  ])))
}
export default { render: render }