import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "clip-rule": "evenodd",
      d: "M 102.4 51.248 C 102.4 22.937 125.337 0 153.648 0 L 870.447 0 C 898.662 0 921.601 22.937 921.601 51.248 L 921.601 204.8 C 921.601 233.11 898.662 256.047 870.447 256.047 C 842.138 256.047 819.2 233.11 819.2 204.8 L 819.2 102.4 L 563.248 102.4 L 563.248 921.601 L 665.648 921.601 C 693.863 921.601 716.8 944.538 716.8 972.848 C 716.8 1001.063 693.863 1024 665.648 1024 L 358.447 1024 C 330.138 1024 307.2 1001.063 307.2 972.848 C 307.2 944.538 330.138 921.601 358.447 921.601 L 460.847 921.601 L 460.847 102.4 L 204.8 102.4 L 204.8 204.8 C 204.8 233.11 181.862 256.047 153.648 256.047 C 125.337 256.047 102.4 233.11 102.4 204.8 Z",
      fill: "rgb(0,0,0)",
      "fill-rule": "evenodd",
      style: {}
    }, null, -1)
  ])))
}
export default { render: render }