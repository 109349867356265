import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  version: "1.1",
  width: "64px",
  height: "64px",
  style: {"shape-rendering":"geometricPrecision","text-rendering":"geometricPrecision","image-rendering":"optimizeQuality","fill-rule":"evenodd","clip-rule":"evenodd"},
  viewBox: "0 0 1024 1024",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:bx": "https://boxy-svg.com"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      style: {"stroke":"rgb(35, 212, 89)","fill":"rgb(35, 212, 89)"},
      d: "M 165.514 9.622 C 165.514 9.622 1011.862 14.567 1011.862 14.257 L 1012 1014.377 L 12.686 1006.998 L 12.08 158.099 C 9.846 50.243 53.824 10.257 165.514 9.622 Z",
      "bx:origin": "0.581469 0.001942"
    }, null, -1),
    _createElementVNode("path", {
      style: {"fill":"rgb(255, 255, 255)"},
      d: "M 153.991 360.341 C 153.991 360.341 456.369 339.194 456.369 338.829 C 456.369 338.464 457.915 420.913 457.915 420.913 C 457.915 420.913 245.494 434.134 245.494 433.769 C 245.494 433.395 244.432 525.962 244.432 525.962 L 426.473 514.769 L 429.929 586.549 L 245.293 599.052 L 244.915 694.685 L 459.419 676.464 L 460.075 759.603 L 152.824 758.259 L 153.991 360.341 Z"
    }, null, -1),
    _createElementVNode("path", {
      style: {"fill":"rgb(255, 255, 255)"},
      d: "M 522.557 327.748 L 521.239 757.968 L 602.55 757.968 L 603.337 461.175 L 801.413 751.113 L 886.571 751.113 C 886.571 751.113 888.786 293.85 888.786 293.464 C 888.786 293.091 818.903 296.347 818.903 296.347 L 812.448 620.54 L 605.136 314.85 L 522.557 327.748 Z"
    }, null, -1)
  ])))
}
export default { render: render }