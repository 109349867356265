import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-1f10064d-554a-4679-b2eb-8693827bdc09-__Capa_1",
  "enable-background": "new 0 0 612 612",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { transform: "matrix(1.673203, 0, 0, 1.673203, -0.000115, -0.000135)" }, [
      _createElementVNode("path", {
        "clip-rule": "evenodd",
        d: "m0 71.993v468.014c0 29.811 24.2 53.993 54.031 53.993h503.937c29.818 0 54.032-24.176 54.032-53.993v-468.014c0-29.811-24.2-53.993-54.031-53.993h-503.938c-29.817 0-54.031 24.176-54.031 53.993zm576 158.924v309.09c0 9.922-8.084 17.993-18.031 17.993h-503.938c-9.955 0-18.031-8.07-18.031-17.993v-309.089c5.64 1.996 11.709 3.082 18.031 3.082h503.937c6.322 0 12.392-1.087 18.032-3.083zm-144-176.917h125.969c9.955 0 18.031 8.07 18.031 17.993v108.014c0 9.922-8.084 17.993-18.031 17.993h-125.969zm-396 17.993c0-9.922 8.084-17.993 18.031-17.993h341.969v144h-341.969c-9.955 0-18.031-8.07-18.031-17.993zm482.272 75.735c7.029 7.029 18.426 7.029 25.456 0 7.029-7.029 7.029-18.426 0-25.456l-27-27c-7.029-7.029-18.426-7.029-25.456 0l-27 27c-7.029 7.029-7.029 18.426 0 25.456 7.029 7.029 18.426 7.029 25.456 0l14.272-14.272zm-428.272-3.728h162.204c9.941 0 18-8.059 18-18s-8.059-18-18-18h-162.204c-9.941 0-18 8.059-18 18s8.059 18 18 18zm0 180h270.204c9.941 0 18-8.059 18-18s-8.059-18-18-18h-270.204c-9.941 0-18 8.059-18 18s8.059 18 18 18zm0 90h162.204c9.941 0 18-8.059 18-18s-8.059-18-18-18h-162.204c-9.941 0-18 8.059-18 18s8.059 18 18 18zm0 90h216.204c9.941 0 18-8.059 18-18s-8.059-18-18-18h-216.204c-9.941 0-18 8.059-18 18s8.059 18 18 18z",
        "fill-rule": "evenodd"
      })
    ], -1)
  ])))
}
export default { render: render }