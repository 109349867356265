import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "enable-background": "new 0 0 24 24",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "405.103",
      cy: "222.609",
      r: "222.609"
    }, null, -1),
    _createElementVNode("path", { d: "M 517.921 926.052 C 494.725 885.894 499.355 836.43 526.959 801.391 C 499.355 766.353 494.725 716.934 517.921 676.73 L 553.939 614.4 C 573.751 580.074 610.704 558.748 650.373 558.748 C 655.805 558.748 661.147 559.149 666.445 559.905 C 667.914 556.255 669.784 552.826 671.61 549.354 C 647.568 539.871 621.612 534.261 594.32 534.261 L 215.885 534.261 C 99.283 534.261 4.407 629.137 4.407 745.739 L 4.407 901.565 C 4.407 919.997 19.366 934.957 37.798 934.957 L 523.041 934.957 Z" }, null, -1),
    _createElementVNode("path", { d: "M 970.662 822.272 C 971.553 815.371 972.755 808.515 972.755 801.391 C 972.755 794.223 971.508 787.411 970.662 780.511 L 1010.776 750.147 C 1019.725 743.379 1022.218 731.002 1016.609 721.252 L 980.591 658.922 C 974.981 649.216 963.005 645.165 952.675 649.528 L 906.506 668.984 C 895.242 660.391 883.266 652.822 870.043 647.302 L 863.899 598.283 C 862.474 587.153 853.036 578.783 841.816 578.783 L 769.825 578.783 C 758.605 578.783 749.122 587.153 747.742 598.283 L 741.598 647.302 C 728.375 652.822 716.354 660.435 705.135 668.984 L 658.966 649.528 C 648.637 645.165 636.66 649.171 631.05 658.922 L 595.032 721.252 C 589.423 730.958 591.916 743.335 600.865 750.147 L 640.979 780.511 C 640.088 787.411 638.886 794.268 638.886 801.391 C 638.886 808.559 640.133 815.371 640.979 822.272 L 600.865 852.636 C 591.916 859.403 589.423 871.78 595.032 881.53 L 631.05 943.905 C 636.66 953.611 648.637 957.663 658.966 953.299 L 705.135 933.799 C 716.399 942.392 728.375 949.96 741.598 955.481 L 747.742 1004.499 C 749.122 1015.63 758.561 1024 769.78 1024 L 841.772 1024 C 852.991 1024 862.474 1015.63 863.855 1004.499 L 869.999 955.481 C 883.222 949.96 895.242 942.347 906.462 933.799 L 952.631 953.255 C 962.96 957.618 974.936 953.611 980.546 943.861 L 1016.564 881.486 C 1022.174 871.78 1019.681 859.403 1010.732 852.591 Z M 805.798 890.435 C 756.602 890.435 716.755 850.588 716.755 801.391 C 716.755 752.195 756.602 712.348 805.798 712.348 C 854.995 712.348 894.842 752.195 894.842 801.391 C 894.842 850.588 854.995 890.435 805.798 890.435 Z" }, null, -1)
  ])))
}
export default { render: render }