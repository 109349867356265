import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-762cefd4-ab28-40c0-b13d-23cdee4e12f1-__svg10654",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      id: "ec-17603295-855c-4795-a67c-b8a4ab3ded3b-__path9425",
      d: "M 464.77 47.408 L 464.398 864.45 L 311.515 711.839 C 302.753 702.834 290.722 697.751 278.157 697.751 C 236.696 698.102 216.337 748.382 245.889 777.464 L 478.486 1010.333 C 496.67 1028.56 526.199 1028.56 544.384 1010.333 L 776.98 777.464 C 822.533 733.476 754.727 666.043 710.991 711.839 L 557.472 865.358 L 557.845 47.408 C 558.311 21.174 537 -0.256 510.762 0.008 C 484.917 0.184 464.266 21.566 464.77 47.408 Z",
      "font-variant-ligatures": "normal",
      "font-variant-position": "normal",
      "font-variant-caps": "normal",
      "font-variant-numeric": "normal",
      "font-variant-alternates": "normal",
      "font-feature-settings": "normal",
      "text-indent": "0",
      "text-align": "start",
      "text-decoration-line": "none",
      "text-decoration-style": "solid",
      "text-decoration-color": "rgb(0,0,0)",
      "text-transform": "none",
      "text-orientation": "mixed",
      "white-space": "normal",
      "shape-padding": "0",
      isolation: "auto",
      "mix-blend-mode": "normal",
      "solid-color": "rgb(0,0,0)",
      "solid-opacity": "1",
      "vector-effect": "none"
    }, null, -1)
  ])))
}
export default { render: render }