import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1611112603872",
  class: "ec-dfd40c33-d73d-49d6-b4cb-b5f10b34629a-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "2257",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, null, -1),
    _createElementVNode("path", {
      d: "M 256 999.619 L 963.048 999.619 C 969.78 999.619 975.238 994.161 975.238 987.428 L 975.238 268.19 L 768 268.19 C 747.837 268.19 731.429 251.782 731.429 231.619 L 731.429 24.381 L 256 24.381 C 249.268 24.381 243.81 29.839 243.81 36.571 L 243.81 987.428 C 243.81 994.161 249.268 999.619 256 999.619 Z",
      fill: "#FFFFFF",
      "p-id": "2258"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 970.191 243.809 L 755.81 29.428 L 755.81 231.619 C 755.81 238.352 761.268 243.809 768 243.809 L 970.191 243.809 Z",
      fill: "#FFFFFF",
      "p-id": "2259"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 988.904 228.047 L 771.572 10.715 C 764.732 3.831 755.421 -0.028 745.716 0 L 256 0 C 235.837 0 219.429 16.408 219.429 36.571 L 219.429 987.428 C 219.429 1007.591 235.837 1024 256 1024 L 963.048 1024 C 983.211 1024 999.619 1007.591 999.619 987.428 L 999.619 253.903 C 999.619 244.151 995.804 234.959 988.904 228.047 Z M 755.81 29.428 L 970.191 243.809 L 768 243.809 C 761.268 243.809 755.81 238.352 755.81 231.619 L 755.81 29.428 Z M 963.048 999.619 L 256 999.619 C 249.268 999.619 243.81 994.161 243.81 987.428 L 243.81 36.571 C 243.81 29.839 249.268 24.381 256 24.381 L 731.429 24.381 L 731.429 231.619 C 731.429 251.782 747.837 268.19 768 268.19 L 975.238 268.19 L 975.238 987.428 C 975.238 994.161 969.78 999.619 963.048 999.619 Z",
      fill: "#605E5C",
      opacity: ".64",
      "p-id": "2260"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 889.905 707.048 L 755.81 707.048 C 749.077 707.048 743.619 701.59 743.619 694.857 L 743.619 670.476 C 743.619 663.743 749.077 658.286 755.81 658.286 L 889.905 658.286 C 896.638 658.286 902.095 663.743 902.095 670.476 L 902.095 694.857 C 902.095 701.59 896.638 707.048 889.905 707.048 Z",
      fill: "#134A2C",
      "p-id": "2261"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 694.857 707.048 L 560.762 707.048 C 554.03 707.048 548.572 701.59 548.572 694.857 L 548.572 670.476 C 548.572 663.743 554.03 658.286 560.762 658.286 L 694.857 658.286 C 701.59 658.286 707.048 663.743 707.048 670.476 L 707.048 694.857 C 707.048 701.59 701.59 707.048 694.857 707.048 Z",
      fill: "#185C37",
      "p-id": "2262"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 889.905 609.524 L 755.81 609.524 C 749.077 609.524 743.619 604.066 743.619 597.333 L 743.619 572.952 C 743.619 566.22 749.077 560.762 755.81 560.762 L 889.905 560.762 C 896.638 560.762 902.095 566.22 902.095 572.952 L 902.095 597.333 C 902.095 604.066 896.638 609.524 889.905 609.524 Z",
      fill: "#21A366",
      "p-id": "2263"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 694.857 609.524 L 560.762 609.524 C 554.03 609.524 548.572 604.066 548.572 597.333 L 548.572 572.952 C 548.572 566.22 554.03 560.762 560.762 560.762 L 694.857 560.762 C 701.59 560.762 707.048 566.22 707.048 572.952 L 707.048 597.333 C 707.048 604.066 701.59 609.524 694.857 609.524 Z",
      fill: "#107C41",
      "p-id": "2264"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 889.905 512 L 755.81 512 C 749.077 512 743.619 506.542 743.619 499.809 L 743.619 475.429 C 743.619 468.696 749.077 463.238 755.81 463.238 L 889.905 463.238 C 896.638 463.238 902.095 468.696 902.095 475.429 L 902.095 499.809 C 902.095 506.542 896.638 512 889.905 512 Z",
      fill: "#33C481",
      "p-id": "2265"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 694.857 512 L 560.762 512 C 554.03 512 548.572 506.542 548.572 499.809 L 548.572 475.429 C 548.572 468.696 554.03 463.238 560.762 463.238 L 694.857 463.238 C 701.59 463.238 707.048 468.696 707.048 475.429 L 707.048 499.809 C 707.048 506.542 701.59 512 694.857 512 Z",
      fill: "#21A366",
      "p-id": "2266"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 73.143 828.952 L 463.238 828.952 C 490.169 828.952 512 807.121 512 780.19 L 512 390.095 C 512 363.165 490.169 341.333 463.238 341.333 L 73.143 341.333 C 46.213 341.333 24.381 363.165 24.381 390.095 L 24.381 780.19 C 24.381 807.121 46.213 828.952 73.143 828.952 Z",
      fill: "#107C41",
      "p-id": "2267"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 133.486 731.428 L 229.596 584.728 L 141.556 438.857 L 212.407 438.857 L 260.462 532.102 C 264.875 540.94 267.91 547.535 269.568 551.887 L 270.19 551.887 C 273.384 544.817 276.688 537.953 280.137 531.285 L 331.508 438.857 L 396.544 438.857 L 306.237 583.924 L 398.836 731.428 L 329.631 731.428 L 274.115 629.004 C 271.5 624.657 269.281 620.083 267.484 615.339 L 266.667 615.339 C 265.692 618.606 263.558 623.019 260.255 628.602 L 203.094 731.428 L 133.486 731.428 Z",
      fill: "#F9F7F7",
      "p-id": "2268"
    }, null, -1)
  ])))
}
export default { render: render }