import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-202d2507-92a7-44b4-9a70-71235960ed64-__Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      transform: "matrix(2, 0, 0, 2, 0, -0.000015)",
      style: {}
    }, [
      _createElementVNode("path", {
        d: "m198.52 321.333h114.96l48.337-154.679c5.345-17.104 8.055-34.862 8.055-52.782 0-62.789-51.083-113.872-113.896-113.872-62.776 0-113.848 51.071-113.848 113.874 0 17.919 2.71 35.677 8.055 52.781z",
        style: {"fill":"rgb(255, 255, 255)"}
      }),
      _createElementVNode("path", {
        d: "m88.366 497c0 8.284 6.716 15 15 15h305.268c8.284 0 15-6.716 15-15v-19.267h-335.268z",
        style: {"fill":"rgb(255, 255, 255)"}
      }),
      _createElementVNode("path", {
        d: "m360.434 351.333h-208.868c-34.905 0-63.2 28.296-63.2 63.2v33.2h335.268v-33.2c0-34.904-28.296-63.2-63.2-63.2z",
        style: {"fill":"rgb(255, 255, 255)"}
      })
    ], -1)
  ])))
}
export default { render: render }