import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      "stroke-linecap": "square",
      "stroke-miterlimit": "10",
      d: "M 724.5 87 L 299.5 512 L 724.5 937",
      style: {"stroke-width":"96px"}
    }, null, -1)
  ])))
}
export default { render: render }