import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-9b7f24f8-efb1-41c0-bcbe-5d1d5756de67-__ICON",
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 832.07 341.046 L 539.334 11.718 C 525.466 -3.906 498.534 -3.906 484.666 11.718 L 191.929 341.046 C 182.342 351.804 180 367.21 185.892 380.346 C 191.783 393.483 204.883 401.936 219.264 401.936 L 329.04 401.936 L 329.04 950.816 C 329.04 991.177 361.863 1024 402.224 1024 L 621.776 1024 C 662.137 1024 694.96 991.177 694.96 950.816 L 694.96 401.972 L 804.736 401.972 C 819.153 401.972 832.216 393.519 838.108 380.383 C 843.999 367.246 841.657 351.841 832.07 341.083 L 832.07 341.046 Z",
      fill: "#8ab942"
    }, null, -1)
  ])))
}
export default { render: render }