import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 -1 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 945.112 640.96 C 931.026 640.96 919.608 652.379 919.608 666.464 L 919.608 892.901 C 919.558 935.141 885.34 969.371 843.1 969.409 L 127.514 969.409 C 85.276 969.371 51.058 935.141 51.006 892.901 L 51.006 228.322 C 51.058 186.094 85.276 151.863 127.514 151.814 L 353.951 151.814 C 368.04 151.814 379.455 140.398 379.455 126.31 C 379.455 112.235 368.04 100.808 353.951 100.808 L 127.514 100.808 C 57.125 100.888 0.079 157.932 0 228.322 L 0 892.911 C 0.079 963.304 57.125 1020.347 127.514 1020.426 L 843.1 1020.426 C 913.493 1020.347 970.535 963.304 970.614 892.911 L 970.614 666.464 C 970.614 652.379 959.197 640.96 945.112 640.96 Z M 945.112 640.96",
      style: {}
    }, null, -1),
    _createElementVNode("path", {
      d: "M 960.512 35.189 C 915.693 -9.63 843.031 -9.63 798.209 35.189 L 343.224 490.175 C 340.107 493.294 337.855 497.159 336.679 501.405 L 276.846 717.412 C 274.386 726.265 276.884 735.75 283.381 742.255 C 289.886 748.752 299.371 751.25 308.228 748.8 L 524.235 688.958 C 528.478 687.784 532.343 685.533 535.461 682.413 L 990.438 227.416 C 1035.188 182.568 1035.188 109.964 990.438 65.114 Z M 398.791 506.752 L 771.164 134.373 L 891.257 254.462 L 518.875 626.846 Z M 374.803 554.888 L 470.748 650.844 L 338.033 687.613 Z M 954.376 191.353 L 927.329 218.4 L 807.227 98.298 L 834.284 71.252 C 859.179 46.356 899.545 46.356 924.44 71.252 L 954.376 101.177 C 979.232 126.102 979.232 166.439 954.376 191.353 Z M 954.376 191.353",
      style: {}
    }, null, -1)
  ])))
}
export default { render: render }