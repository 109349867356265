import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 1024 1024",
  style: {"enable-background":"new 0 0 48 48"},
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), { type: "text/css" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(".ec-1eae12a7-3512-40ee-bc94-948622378d85-__st0{display:none}.ec-e38e0ab3-e8a0-4c42-8982-0711ad3763dd-__st1{fill:#FFFFFF}.ec-2b2784c0-be52-4a4f-82e6-a574e1f9c8ff-__st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF}.ec-bacab3b2-1d76-4003-bd94-1f667c40b46a-__st3{fill-rule:evenodd;clip-rule:evenodd}")
      ])),
      _: 1
    })),
    _cache[1] || (_cache[1] = _createStaticVNode("<g id=\"ec-d5c46754-75fd-4298-8caf-29511f857604-__图层_1\" transform=\"matrix(21.322109, 0, 0, 21.322109, 0.269359, -39.176537)\"><path d=\"M39.2,8H23.6c-0.5,0-0.9-0.1-1.3-0.4l-2.8-1.9c-0.9-0.6-2-0.9-3.1-0.9H8.8c-3.1,0-5.6,2.5-5.6,5.6v2.4h41.6 C44.8,9.7,42.3,8,39.2,8z\"></path><path d=\"M40.8,43.2H7.2c-3.1,0-5.5-2.5-5.6-5.5L0,21.2v0C-0.2,18.4,2,16,4.8,16h38.4c2.8,0,5,2.4,4.8,5.2v0l-1.6,16.5 C46.3,40.7,43.8,43.2,40.8,43.2z\"></path></g><g id=\"ec-9e0dfe13-ca63-4a69-9193-c911c9500f13-__图层_2\" transform=\"matrix(21.322109, 0, 0, 21.322109, 0.269359, -39.176537)\"><g class=\"ec-1eae12a7-3512-40ee-bc94-948622378d85-__st0\"><path class=\"ec-e38e0ab3-e8a0-4c42-8982-0711ad3763dd-__st1\" d=\"M37.8,76.5v-1.8c0-0.2,0.1-0.3,0.3-0.3h3.7v-3.7c0-0.2,0.1-0.3,0.3-0.3h1.8c0.2,0,0.3,0.1,0.3,0.3v3.7h3.7 c0.2,0,0.3,0.1,0.3,0.3v1.8c0,0.2-0.1,0.3-0.3,0.3h-3.7v3.7c0,0.2-0.1,0.3-0.3,0.3h-1.8c-0.2,0-0.3-0.1-0.3-0.3v-3.7h-3.7 C37.9,76.8,37.8,76.7,37.8,76.5L37.8,76.5z M37.8,76.5\"></path></g><g><circle class=\"ec-2b2784c0-be52-4a4f-82e6-a574e1f9c8ff-__st2\" cx=\"34.3\" cy=\"33.6\" r=\"13.3\"></circle><path class=\"ec-bacab3b2-1d76-4003-bd94-1f667c40b46a-__st3\" d=\"M34.3,21.5c-6.7,0-12.2,5.5-12.2,12.2s5.5,12.2,12.2,12.2s12.2-5.5,12.2-12.2S41,21.5,34.3,21.5L34.3,21.5z M34.3,21.5\"></path><path class=\"ec-e38e0ab3-e8a0-4c42-8982-0711ad3763dd-__st1\" d=\"M27.6,34.8v-2.3c0-0.2,0.2-0.4,0.4-0.4h4.7v-4.7c0-0.2,0.2-0.4,0.4-0.4h2.3c0.2,0,0.4,0.2,0.4,0.4v4.7h4.7 c0.2,0,0.4,0.2,0.4,0.4v2.3c0,0.2-0.2,0.4-0.4,0.4h-4.7v4.7c0,0.2-0.2,0.4-0.4,0.4h-2.3c-0.2,0-0.4-0.2-0.4-0.4v-4.7H28 C27.8,35.2,27.6,35,27.6,34.8L27.6,34.8z M27.6,34.8\"></path></g></g>", 2))
  ]))
}
export default { render: render }