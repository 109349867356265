import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1611112610614",
  class: "ec-2f5cb097-b526-47cf-8b2a-2977b002bb06-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "2408",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, null, -1),
    _createElementVNode("path", {
      d: "M 256 999.619 L 963.048 999.619 C 969.78 999.619 975.238 994.161 975.238 987.428 L 975.238 268.19 L 768 268.19 C 747.837 268.19 731.429 251.782 731.429 231.619 L 731.429 24.381 L 256 24.381 C 249.268 24.381 243.81 29.839 243.81 36.571 L 243.81 987.428 C 243.81 994.161 249.268 999.619 256 999.619 Z",
      fill: "#FFFFFF",
      "p-id": "2409"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 970.191 243.809 L 755.81 29.428 L 755.81 231.619 C 755.81 238.352 761.268 243.809 768 243.809 L 970.191 243.809 Z",
      fill: "#FFFFFF",
      "p-id": "2410"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 988.904 228.047 L 771.572 10.715 C 764.732 3.831 755.421 -0.028 745.716 0 L 256 0 C 235.837 0 219.429 16.408 219.429 36.571 L 219.429 987.428 C 219.429 1007.591 235.837 1024 256 1024 L 963.048 1024 C 983.211 1024 999.619 1007.591 999.619 987.428 L 999.619 253.903 C 999.619 244.151 995.804 234.959 988.904 228.047 Z M 755.81 29.428 L 970.191 243.809 L 768 243.809 C 761.268 243.809 755.81 238.352 755.81 231.619 L 755.81 29.428 Z M 963.048 999.619 L 256 999.619 C 249.268 999.619 243.81 994.161 243.81 987.428 L 243.81 36.571 C 243.81 29.839 249.268 24.381 256 24.381 L 731.429 24.381 L 731.429 231.619 C 731.429 251.782 747.837 268.19 768 268.19 L 975.238 268.19 L 975.238 987.428 C 975.238 994.161 969.78 999.619 963.048 999.619 Z",
      fill: "#605E5C",
      opacity: ".64",
      "p-id": "2411"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 670.476 585.143 L 658.286 572.952 L 560.762 572.952 L 548.572 585.143 L 548.572 721.164 C 580.937 750.19 623.58 768 670.476 768 C 771.462 768 853.334 686.129 853.334 585.143 L 670.476 585.143 Z",
      fill: "#ED6C47",
      "p-id": "2412"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 548.572 449.121 L 548.572 585.143 L 670.476 585.143 L 670.476 402.286 C 623.58 402.286 580.937 420.084 548.572 449.121 Z",
      fill: "#FF8F6B",
      "p-id": "2413"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 707.048 365.714 L 707.048 548.571 L 889.905 548.571 C 889.905 447.585 808.034 365.714 707.048 365.714 Z",
      fill: "#FFC7B5",
      "p-id": "2414"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 73.143 828.952 L 463.238 828.952 C 490.169 828.952 512 807.121 512 780.19 L 512 390.095 C 512 363.165 490.169 341.333 463.238 341.333 L 73.143 341.333 C 46.213 341.333 24.381 363.165 24.381 390.095 L 24.381 780.19 C 24.381 807.121 46.213 828.952 73.143 828.952 Z",
      fill: "#C43E1C",
      "p-id": "2415"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 290.792 438.857 C 326.815 438.857 354.426 446.939 373.565 463.055 C 392.704 479.183 402.286 502.552 402.286 533.126 C 402.286 552.765 397.544 570.222 388.084 585.508 C 378.612 600.808 365.142 612.693 347.685 621.178 C 330.24 629.662 310.004 633.905 287.001 633.905 L 231.607 633.905 L 231.607 743.619 L 170.667 743.619 L 170.667 438.857 L 290.792 438.857 Z M 231.619 585.143 L 279.979 585.143 C 298.825 585.143 313.064 580.876 322.707 572.404 C 332.337 563.907 337.14 551.497 337.14 535.174 C 337.14 503.479 318.696 487.619 281.771 487.619 L 231.619 487.619 L 231.619 585.143 Z",
      fill: "#F9F7F7",
      "p-id": "2416"
    }, null, -1)
  ])))
}
export default { render: render }