import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  height: "48px",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M 947.32 419.976 C 919.32 399.216 884.92 383.976 848.6 375.756 C 836.427 373.017 826.929 363.496 824.22 351.316 C 808.62 281.816 775.04 222.216 725.68 177.056 C 668.3 124.476 592.42 95.556 512 95.556 C 441.3 95.556 376 117.716 323.26 159.656 C 287.516 188.125 258.745 224.38 239.14 265.656 C 234.83 274.814 226.441 281.393 216.52 283.396 C 163.02 294.196 114.72 317.136 77.84 349.636 C 26.92 394.636 0 454.456 0 522.756 C 0 591.796 28.98 654.756 81.58 700.276 C 131.82 743.656 199.46 767.556 272 767.556 L 480 767.556 L 480 460.816 L 406.62 534.196 C 393.694 547.121 372.586 546.61 360.3 533.076 C 348.7 520.336 349.82 500.476 362 488.296 L 489.38 360.936 C 501.875 348.449 522.125 348.449 534.62 360.936 L 662 488.256 C 674.56 500.836 675.28 521.456 662.78 534.076 C 650.305 546.676 629.962 546.73 617.42 534.196 L 544 460.816 L 544 767.556 L 792 767.556 C 854.68 767.556 911.82 749.956 952.9 718.016 C 999.42 681.816 1024 630.016 1024 568.356 C 1024 508.476 997.48 457.136 947.32 419.976 Z" }, null, -1),
    _createElementVNode("path", { d: "M 480 896.396 C 480 921.03 506.667 936.426 528 924.109 C 537.901 918.393 544 907.828 544 896.396 L 544 767.556 L 480 767.556 Z" }, null, -1)
  ])))
}
export default { render: render }