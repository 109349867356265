import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "ec-4c48ec1e-3339-4023-98e1-58fe0f15e880-__Layer_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 1024 1024",
  style: {"enable-background":"new 0 0 512 512"},
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g transform=\"matrix(2, 0, 0, 2, 0, 0)\"><g><rect x=\"362\" y=\"362\" width=\"150\" height=\"150\"></rect></g></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"><g><path d=\"M482,60H150V0H0v150h150V90h332v151h-85.458v-30.557L351,255.997l45.542,45.564V271H482c16.542,0,30-13.458,30-30V90 C512,73.458,498.542,60,482,60z\"></path></g></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"><g><path d=\"M342,436.997l-45.542-45.553V422H31V271h150v60h150V181H181v60H31c-16.542,0-30,13.458-30,30v151 c0,16.542,13.458,30,30,30h265.458v30.561L342,436.997z\"></path></g></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g><g transform=\"matrix(2, 0, 0, 2, 0, 0)\"></g>", 18)
  ])))
}
export default { render: render }