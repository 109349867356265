import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 962 512 C 962 263.562 760.438 62 512 62 C 263.562 62 62 263.562 62 512 C 62 760.438 263.562 962 512 962 C 760.438 962 962 760.438 962 512 Z",
      fill: "none",
      "stroke-miterlimit": "10",
      style: {"stroke-width":"64px"}
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M 512 324.5 L 512 699.5",
      style: {"stroke-width":"64px"}
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M 699.5 512 L 324.5 512",
      style: {"stroke-width":"64px"}
    }, null, -1)
  ])))
}
export default { render: render }