import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 932.366 182.857 L 877.92 182.857 C 877.806 182.857 877.714 182.949 877.714 183.063 L 877.714 877.714 C 877.714 918.11 910.461 950.857 950.857 950.857 C 991.253 950.857 1024 918.11 1024 877.714 L 1024 274.491 C 1024 223.883 982.974 182.857 932.366 182.857 Z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 804.571 877.714 L 804.571 91.429 C 804.571 40.934 763.637 0 713.143 0 L 91.429 0 C 40.934 0 0 40.934 0 91.429 L 0 896 C 0 966.692 57.308 1024 128 1024 L 911.657 1024 C 913.114 1024.013 914.298 1022.828 914.286 1021.371 C 914.283 1020.19 913.486 1019.158 912.343 1018.857 C 848.756 1001.414 804.65 943.65 804.571 877.714 Z M 146.286 219.429 C 146.286 199.231 162.659 182.857 182.857 182.857 L 329.143 182.857 C 349.341 182.857 365.714 199.231 365.714 219.429 L 365.714 365.714 C 365.714 385.912 349.341 402.286 329.143 402.286 L 182.857 402.286 C 162.659 402.286 146.286 385.912 146.286 365.714 Z M 621.714 841.143 L 183.886 841.143 C 164.206 841.143 147.314 826.011 146.331 806.331 C 145.326 785.468 161.97 768.002 182.857 768 L 620.686 768 C 640.366 768 657.257 783.131 658.24 802.811 C 659.245 823.675 642.602 841.141 621.714 841.143 Z M 621.714 694.857 L 183.886 694.857 C 164.206 694.857 147.314 679.726 146.331 660.046 C 145.326 639.182 161.97 621.716 182.857 621.714 L 620.686 621.714 C 640.366 621.714 657.257 636.846 658.24 656.526 C 659.245 677.389 642.602 694.855 621.714 694.857 Z M 621.714 548.571 L 183.886 548.571 C 164.206 548.571 147.314 533.44 146.331 513.76 C 145.326 492.897 161.97 475.43 182.857 475.429 L 620.686 475.429 C 640.366 475.429 657.257 490.56 658.24 510.24 C 659.245 531.103 642.602 548.57 621.714 548.571 Z M 621.714 402.286 L 476.457 402.286 C 456.777 402.286 439.886 387.154 438.903 367.474 C 437.898 346.611 454.541 329.145 475.429 329.143 L 620.686 329.143 C 640.366 329.143 657.257 344.274 658.24 363.954 C 659.245 384.818 642.602 402.284 621.714 402.286 Z M 621.714 256 L 476.457 256 C 456.777 256 439.886 240.869 438.903 221.189 C 437.898 200.325 454.541 182.859 475.429 182.857 L 620.686 182.857 C 640.366 182.857 657.257 197.989 658.24 217.669 C 659.245 238.532 642.602 255.998 621.714 256 Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }