import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "48px",
  viewBox: "0 0 1024 1024",
  width: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 512 0 C 229.672 0 0 229.672 0 512 C 0 794.328 229.672 1024 512 1024 C 794.328 1024 1024 794.328 1024 512 C 1024 229.672 794.328 0 512 0 Z M 512 0",
      style: {"fill":"rgb(170, 170, 170)"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M 700.546 640.21 C 717.226 656.898 717.226 683.86 700.546 700.546 C 692.226 708.868 681.304 713.046 670.376 713.046 C 659.454 713.046 648.532 708.868 640.21 700.546 L 512 572.328 L 383.79 700.546 C 375.468 708.868 364.546 713.046 353.626 713.046 C 342.696 713.046 331.774 708.868 323.454 700.546 C 306.774 683.86 306.774 656.898 323.454 640.21 L 451.672 512 L 323.454 383.79 C 306.774 367.102 306.774 340.14 323.454 323.454 C 340.14 306.774 367.102 306.774 383.79 323.454 L 512 451.672 L 640.21 323.454 C 656.898 306.774 683.86 306.774 700.546 323.454 C 717.226 340.14 717.226 367.102 700.546 383.79 L 572.328 512 Z M 700.546 640.21",
      fill: "#fafafa"
    }, null, -1)
  ])))
}
export default { render: render }