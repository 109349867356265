import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  t: "1611112651231",
  class: "ec-952d31b9-c7be-49ed-9011-e79c92df2aec-__icon",
  viewBox: "0 0 1024 1024",
  version: "1.1",
  "p-id": "11718",
  width: "48",
  height: "48",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 870.412 1024 L 153.589 1024 C 125.312 1024 102.387 1001.077 102.387 972.8 L 102.387 51.201 C 102.387 22.924 125.312 0 153.589 0 L 723.294 0 C 734.534 -0.02 745.328 4.396 753.331 12.288 L 909.326 168.278 C 917.218 176.281 921.634 187.075 921.614 198.316 L 921.614 972.8 C 921.614 1001.077 898.69 1024 870.412 1024 Z M 153.589 34.134 C 144.164 34.134 136.522 41.775 136.522 51.201 L 136.522 972.8 C 136.522 982.226 144.164 989.868 153.589 989.868 L 870.412 989.868 C 879.838 989.868 887.48 982.226 887.48 972.8 L 887.48 198.316 C 887.617 196.116 886.736 193.975 885.09 192.512 L 729.095 36.523 C 727.631 34.877 725.491 33.996 723.294 34.134 Z",
      fill: "#4D4D4D",
      "p-id": "11719"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 904.547 204.799 L 733.874 204.799 C 724.448 204.799 716.807 197.159 716.807 187.733 L 716.807 17.066 L 750.941 17.066 L 750.941 170.667 L 904.547 170.667 Z",
      fill: "#4D4D4D",
      "p-id": "11720"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 204.792 170.667 L 648.539 170.667 L 648.539 204.799 L 204.792 204.799 Z M 204.792 307.2 L 819.211 307.2 L 819.211 341.334 L 204.792 341.334 Z M 204.792 443.733 L 819.211 443.733 L 819.211 477.867 L 204.792 477.867 Z M 204.792 580.267 L 819.211 580.267 L 819.211 614.4 L 204.792 614.4 Z M 204.792 853.334 L 819.211 853.334 L 819.211 887.467 L 204.792 887.467 Z M 204.792 716.8 L 819.211 716.8 L 819.211 750.935 L 204.792 750.935 Z",
      fill: "#B3B3B3",
      "p-id": "11721"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 51.187 460.8 M 68.254 460.8 L 955.748 460.8 C 967.126 460.8 972.815 466.489 972.815 477.867 L 972.815 750.935 C 972.815 762.311 967.126 768.001 955.748 768.001 L 68.254 768.001 C 56.875 768.001 51.187 762.311 51.187 750.935 L 51.187 477.867 C 51.187 466.489 56.875 460.8 68.254 460.8 Z",
      fill: "#F33958",
      "p-id": "11722"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 955.748 477.867 L 955.748 750.935 L 68.254 750.935 L 68.254 477.867 L 955.748 477.867 M 955.748 443.733 L 68.254 443.733 C 49.401 443.733 34.119 459.015 34.119 477.867 L 34.119 750.935 C 34.119 769.785 49.401 785.066 68.254 785.066 L 955.748 785.066 C 974.6 785.066 989.882 769.785 989.882 750.935 L 989.882 477.867 C 989.882 459.015 974.6 443.733 955.748 443.733 Z",
      fill: "#C42E47",
      "p-id": "11723"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 348.156 530.09 C 391.118 526.729 421.61 571.133 403.04 610.02 C 393.061 630.917 371.243 643.514 348.156 641.706 L 307.195 641.706 L 307.195 698.71 L 282.277 698.71 L 282.277 530.09 Z M 307.195 618.839 L 341.328 618.839 C 363.857 618.839 377.17 607.233 377.17 586.412 C 377.17 565.59 364.199 552.278 341.328 552.278 L 307.195 552.278 Z M 509.953 530.09 C 557.157 526.614 595.569 567.508 589.145 614.4 C 595.489 661.373 557.259 702.403 509.953 699.392 L 449.194 699.392 L 449.194 530.09 Z M 475.818 674.816 L 507.563 674.816 C 540.945 676.672 567.658 647.486 562.861 614.4 C 567.177 581.385 540.473 552.551 507.222 554.326 L 475.476 554.326 Z M 636.591 698.71 L 636.591 530.09 L 741.725 530.09 L 741.725 553.984 L 662.876 553.984 L 662.876 605.868 L 734.898 605.868 L 734.898 628.736 L 662.876 628.736 L 662.876 697.002 Z",
      fill: "#FFFFFF",
      "p-id": "11724"
    }, null, -1)
  ])))
}
export default { render: render }