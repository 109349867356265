import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ec-5013edf4-5985-4ec4-ac57-25bf1550e966-__Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".ec-9ead24b7-2366-4a4d-9e03-273fb3425a3d-__cls-1{fill:none}.ec-cb41da90-f54e-43e8-a31c-4472d2d2d06b-__cls-2{clip-path:url(#ec-bbed9772-2728-4f82-b8a7-2915ec980849-__clippath)}")
        ])),
        _: 1
      })),
      _cache[1] || (_cache[1] = _createElementVNode("clipPath", { id: "ec-bbed9772-2728-4f82-b8a7-2915ec980849-__clippath" }, [
        _createElementVNode("rect", {
          class: "ec-9ead24b7-2366-4a4d-9e03-273fb3425a3d-__cls-1",
          width: "384",
          height: "384"
        })
      ], -1))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("g", {
      class: "ec-cb41da90-f54e-43e8-a31c-4472d2d2d06b-__cls-2",
      transform: "matrix(2.666945, 0, 0, 2.666945, -0.053384, -0.053396)"
    }, [
      _createElementVNode("path", { d: "M234.62,379.13c1.16,0,2.34-.14,3.52-.42,40.83-9.84,77.82-33.43,104.16-66.45,13.17-16.5,23.42-34.91,30.48-54.71C427.58,102.21,271.43-51,118.54,16.27c-22.67,10.13-43.08,24.54-60.67,42.8-13.22,14.88,7.25,34.57,21.6,20.79,100.27-105.68,274.61-30.39,274.34,113.74,0,74.42-50.45,138.55-122.69,155.96-8.05,1.94-13,10.04-11.06,18.09,1.66,6.87,7.8,11.48,14.56,11.48h0ZM.02,92.42V49.7c.79-19.89,29.2-19.88,29.98,0v42.72c0,8.27,6.72,14.99,14.99,14.99h42.72c19.89,.79,19.88,29.2,0,29.99H44.99C20.19,137.4,.02,117.22,.02,92.42H.02Zm18.74,82.45c-24.86,.99-24.84,36.5,0,37.48,24.86-.99,24.85-36.5,0-37.48h0Zm29.98,79.45c0,10.35-8.39,18.74-18.74,18.74-24.86-.99-24.84-36.5,0-37.48,10.35,0,18.74,8.39,18.74,18.74h0Zm32.23,54.72c0,10.35-8.39,18.74-18.74,18.74-24.86-.99-24.84-36.5,0-37.48,10.35,0,18.74,8.39,18.74,18.74h0Zm49.47,38.23c0,10.35-8.39,18.74-18.74,18.74-24.86-.99-24.85-36.5,0-37.48,10.35,0,18.74,8.39,18.74,18.74h0Zm62.22,17.99c0,10.35-8.39,18.74-18.74,18.74-24.86-.99-24.84-36.5,0-37.48,10.35,0,18.74,8.39,18.74,18.74h0Zm0,0" })
    ], -1))
  ]))
}
export default { render: render }