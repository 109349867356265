import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M 62 737 L 512 287 L 962 737",
      style: {"stroke-width":"96px"}
    }, null, -1)
  ])))
}
export default { render: render }