import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1024 1024",
  width: "48px",
  height: "48px",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 905.144 438.857 L 585.144 438.857 C 524.55 438.857 475.429 389.736 475.429 329.143 L 475.429 9.143 C 475.429 4.093 471.336 0 466.286 0 L 256.001 0 C 175.209 0 109.715 65.494 109.715 146.286 L 109.715 877.714 C 109.715 958.506 175.209 1024 256.001 1024 L 768.001 1024 C 848.792 1024 914.286 958.506 914.286 877.714 L 914.286 448 C 914.286 442.951 910.193 438.857 905.144 438.857 Z M 694.858 804.571 L 329.144 804.571 C 300.991 804.571 283.395 774.095 297.472 749.714 C 304.005 738.399 316.078 731.429 329.144 731.429 L 694.858 731.429 C 723.011 731.429 740.606 761.905 726.53 786.286 C 719.997 797.601 707.924 804.571 694.858 804.571 Z M 694.858 621.714 L 329.144 621.714 C 300.991 621.714 283.395 591.238 297.472 566.857 C 304.005 555.542 316.078 548.571 329.144 548.571 L 694.858 548.571 C 723.011 548.571 740.606 579.048 726.53 603.429 C 719.997 614.744 707.924 621.714 694.858 621.714 Z",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 885.075 357.92 L 556.366 29.211 C 553.871 26.73 549.625 27.882 548.724 31.283 C 548.625 31.659 548.574 32.046 548.572 32.434 L 548.572 329.143 C 548.572 349.341 564.946 365.714 585.144 365.714 L 881.852 365.714 C 885.371 365.7 887.555 361.881 885.782 358.841 C 885.587 358.505 885.349 358.196 885.075 357.92 Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }